<template>
  <div class="d-flex align-center mb-3">
    <v-icon size="40" icon="$securityAwarenessSmall"></v-icon>
    <div class="headline6">
      {{ $t(`reports.executiveSummaryReport.securityAwareness.title`) }}
    </div>
  </div>
  <div class="body1 mb-6 flex-column">
    <div>
      {{ $t(`reports.executiveSummaryReport.securityAwareness.description`) }}
    </div>
    <div
      v-if="isModuleDisabled(SubscriptionModule.SECURITY_AWARENESS)"
      class="body1 text--semitransparent mt-2 d-flex align-center"
    >
      <v-icon class="mr-1" icon="$warning" size="24" />
      <span>{{ $t(`reports.executiveSummaryReport.disabledModuleNotification`) }}</span>
    </div>
  </div>
  <v-row class="bg-indigo-faint d-flex pa-10 rounded-lg" style="gap: 40px" no-gutters>
    <v-col :cols="2">
      <div class="d-flex flex-column">
        <div class="big-number mb-2 text-primary">
          {{ data.emailsSent }}
        </div>
        <div class="subtitle2 mb-2">
          {{ $t("reports.executiveSummaryReport.securityAwareness.simulationsSent") }}
        </div>
      </div>
      <div class="d-flex flex-column mt-4">
        <div class="big-number mb-2 text-primary">
          {{ data.trainingsSent }}
        </div>
        <div class="subtitle2 mb-2">
          {{ $t("reports.executiveSummaryReport.securityAwareness.trainingsSent") }}
        </div>
      </div>
    </v-col>
    <v-col :cols="6">
      <div class="subtitle1">
        {{ $t("reports.executiveSummaryReport.securityAwareness.trainingsCompleted") }}
      </div>
      <div
        v-for="{ trainingName, count } in trainingsCompleted"
        :key="trainingName"
        class="es-report-module-overview__ticket-type d-flex text-right pa-2"
      >
        <v-row>
          <v-col class="body2">
            {{ trainingName }}
          </v-col>
          <v-col class="d-flex align-center">
            <div
              v-if="count !== 0"
              class="percentage-bar bg-indigo-light rounded-pill mr-2"
              :style="getProgressStyle(count)"
            ></div>
            <span class="body2 text--semibold" :class="{ 'text--semitransparent': count === 0 }">
              {{ count }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col :cols="2">
      <div class="mb-12">
        <div class="subtitle1">
          {{ $t(`reports.executiveSummaryReport.securityAwareness.topPhishedUsers`) }}
        </div>
        <template v-for="(count, user, index) in data.topUsersFailedPhishing" :key="user">
          <div class="d-flex justify-space-between text-primary py-2">
            <div class="caption word-break-all">
              {{ user }}
            </div>
            <div class="caption ml-4">
              {{ count }}
            </div>
          </div>
          <v-divider
            v-if="index !== Object.keys(data.topUsersFailedPhishing).length - 1"
            class="border-opacity-100"
            color="white"
          />
        </template>
      </div>
      <div>
        <div class="subtitle1">
          {{ $t(`reports.executiveSummaryReport.securityAwareness.topOverdueUsers`) }}
        </div>
        <template v-for="(count, user, index) in data.topUsersFailedTraining" :key="user">
          <div class="d-flex justify-space-between text-primary py-2">
            <div class="caption word-break-all">
              {{ user }}
            </div>
            <div class="caption ml-4">
              {{ count }}
            </div>
          </div>
          <v-divider
            v-if="index !== Object.keys(data.topUsersFailedTraining).length - 1"
            class="border-opacity-100"
            color="white"
          />
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { CoroIcons } from "@/constants/coro-icon";
import { SubscriptionModule } from "@/constants/workplaces";
import { isModuleDisabled } from "@/_helpers/utils";
import type { ExecutiveSummarySecurityAwarenessReportData } from "@/_store/reports.module.ts";

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<ExecutiveSummarySecurityAwarenessReportData>,
      required: true,
    },
  },
  setup(props) {
    const highestNumberOfTrainings = computed(() =>
      Math.max(...Object.values(props.data.trainingsCompleted))
    );
    const trainingsCompleted = computed(() =>
      Object.entries(props.data.trainingsCompleted).map(
        ([trainingName, count]) =>
          ({
            trainingName,
            count,
          }) as { trainingName: string; count: number }
      )
    );
    const getProgressStyle = (count: number) => {
      const percentage =
        count === highestNumberOfTrainings.value
          ? 100
          : (count / highestNumberOfTrainings.value) * 100;
      return `width: ${Math.ceil(percentage)}%;`;
    };

    return {
      isModuleDisabled,
      trainingsCompleted,
      getProgressStyle,
      CoroIcons,
      SubscriptionModule,
    };
  },
});
</script>

<style lang="scss" scoped>
.percentage-bar {
  height: 24px;
}

:deep(*) {
  .icon-warning:before {
    color: rgb(var(--v-theme-primary)) !important;
  }
}
</style>
