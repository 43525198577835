<template>
  <div>
    <div class="d-flex justify-end allow-blocklist-tab-content">
      <v-menu offset-y>
        <template #activator="{ props }">
          <v-btn
            class="add-btn allow-blocklist-tab-add-btn"
            data-testid="allow-block-page-add-btn"
            color="primary"
            rounded
            large
            v-bind="props"
          >
            <v-icon color="white" class="mr-2" icon="$add"></v-icon>
            <span>{{ $t("general.add") }}</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            data-testid="allow-block-page-add-groups-action"
            class="v-list-item v-list-item--link theme--light"
            @click="addItemToAllowBlock(PhishingAction.ADD_TO_ALLOWLIST)"
          >
            <v-list-item-title>
              {{ $t("phishingSettings.actions.addToAllowlist") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            data-testid="allow-block-page-add-all-users-action"
            class="v-list-item v-list-item--link theme--light"
            @click="addItemToAllowBlock(PhishingAction.ADD_TO_BLOCKLIST)"
          >
            <v-list-item-title>
              {{ $t("phishingSettings.actions.addToBlocklist") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            data-testid="allow-block-page-import-from-csv"
            class="v-list-item v-list-item--link theme--light"
            @click="importFromCsv()"
          >
            <v-list-item-title>
              {{ $t("phishingSettings.actions.importFromCSV") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="allow-blocklist-tab-content allow-blocklist-tab-filters w-100">
      <filter-wrapper
        class="mb-5"
        :show-clear-button="showClearFiltersButton"
        @clear-filters-clicked="clearFilters(clearFiltersCallback)"
      >
        <div class="grid-container">
          <v-select
            v-model="localFilters.type"
            :items="types"
            class="filter-menu"
            :class="{ 'filter-active': localFilters.type }"
            :placeholder="$t('phishingSettings.allEntities')"
            density="compact"
            variant="outlined"
            rounded
          >
            <template #item="{ item, props }">
              <v-list-item
                v-bind="props"
                :title="$t(`phishingSettings.${item.title}`)"
              ></v-list-item>
            </template>
            <template #selection="{ item }">
              {{ $t(`phishingSettings.${item.title}`) }}
            </template>
          </v-select>
          <v-select
            v-model="localFilters.listType"
            :items="lists"
            class="filter-menu"
            :class="{ 'filter-active': localFilters.listType }"
            :placeholder="$t('phishingSettings.allLists')"
            density="compact"
            variant="outlined"
            rounded
          >
            <template #item="{ item, props }">
              <v-list-item v-bind="props" :title="$t(`phishingSettings.${item.title}`)">
              </v-list-item>
            </template>
            <template #selection="{ item }">
              {{ $t(`phishingSettings.${item.title}`) }}
            </template>
          </v-select>
          <v-text-field
            v-model="localFilters.search"
            data-testid="users-page-users-search"
            :class="{ 'filter-active': localFilters.search?.length }"
            density="compact"
            variant="outlined"
            :placeholder="$t('general.search')"
            prepend-inner-icon="icon-search"
            rounded
            clearable
            clear-icon="$x"
          />
        </div>
      </filter-wrapper>
    </div>
    <v-progress-linear
      :style="{ visibility: loading ? 'visible' : 'hidden' }"
      class="allow-blocklist-tab-content"
      indeterminate
      height="2px"
    />
    <v-switch
      v-if="showApplyToChildWorkspaces"
      v-model="localValue.applyToChildWorkspaces"
      class="mb-4 allow-blocklist-tab-content"
      color="primary lighten-1"
      hide-details
      :ripple="false"
      :disabled="actionNotAllowed()"
    >
      <template #label>
        <div class="text--black">
          {{ $t(`phishingSettings.applyToChildWorkspaces`) }}
        </div>
      </template>
    </v-switch>
    <phishing-allow-block-list-table
      class="allow-blocklist-tab-content"
      :items="allowBlockList"
      :item-actions="actions"
      :pages-count="pages"
      :total-items="total"
      :pagination="pagination"
      @on-item-action="onActionClick($event)"
      @page-changed="onPageChanged($event)"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type { AllowBlockListItem, SortItem } from "@/_store/email-security/email-settings.module";
import { useEmailSettingsStore } from "@/_store/email-security/email-settings.module";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/_store";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule, WorkspaceType } from "@/constants/workplaces";
import {
  AllowBlockListType,
  EmailSecurityAllowBlockItemType,
  PhishingAction,
} from "@/constants/phishing";
import { FilterContext } from "@/_store/filters.module";
import { debounce } from "lodash";
import isEqual from "lodash/isEqual";
import { useFilters } from "@/composables/useFilters";
import FilterWrapper from "@/components/FilterWrapper.vue";
import PhishingAllowBlockListTable from "@/components/tables/PhishingAllowBlockListTable.vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import EmailSettingsAllowBlockListModal from "@/components/modals/email-settings/EmailSettingsAllowBlockListModal.vue";
import ImportCSVModal from "@/components/modals/ImportCSVModal.vue";
import type { Pagination } from "@/types";

export default defineComponent({
  components: {
    FilterWrapper,
    PhishingAllowBlockListTable,
  },
  setup() {
    const emailSettingsStore = useEmailSettingsStore();
    const accountStore = useAccountStore();
    const { allowBlockList, pages, pagination, total, loading, settings } =
      storeToRefs(emailSettingsStore);
    const dialogsStore = useDialogsStore();

    const { account } = accountStore;
    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } = useFilters(
      FilterContext.ALLOW_BLOCK_LIST
    );

    const localValue = ref({
      applyToChildWorkspaces: false,
    });
    const showApplyToChildWorkspaces = computed(() => {
      return account.workspaceType === WorkspaceType.CHANNEL;
    });

    const actionNotAllowed = () => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.EMAIL_SECURITY
      );
    };

    const clearFiltersCallback = async () => {
      emailSettingsStore.resetPagination();
      await emailSettingsStore.getAllowBlockList();
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) {
          emailSettingsStore.resetPagination();
          await emailSettingsStore.getAllowBlockList();
        }
      },
      { deep: true }
    );

    watch(
      () => localValue.value.applyToChildWorkspaces,
      debounce(async (newVal) => {
        if (!isEqual(newVal, settings.value!.senderClassificationInheritanceEnabled)) {
          await emailSettingsStore.updateEmailsSettings({
            ...settings.value!,
            senderClassificationInheritanceEnabled: newVal,
          });
          await emailSettingsStore.getEmailsSettings();
        }
      }, 500)
    );

    onMounted(async () => {
      emailSettingsStore.resetPagination();
      await emailSettingsStore.getEmailsSettings();
      await emailSettingsStore.getAllowBlockList();

      localValue.value.applyToChildWorkspaces =
        !!settings.value?.senderClassificationInheritanceEnabled;
    });

    const onPageChanged = ({
      pagination,
      sortingOptions,
    }: {
      pagination: Pagination;
      sortingOptions: SortItem[];
    }) => {
      emailSettingsStore.setPagination(pagination);
      emailSettingsStore.setSortingOptions(sortingOptions);
      emailSettingsStore.getAllowBlockList();
    };

    const addItemToAllowBlock = (action: PhishingAction) => {
      const dialogConfig = componentDialogsConfigConstructor({
        item: {
          allowList: action === PhishingAction.ADD_TO_ALLOWLIST,
        },
        action: action,
        component: EmailSettingsAllowBlockListModal,
        width: ModalWidth.LARGE,
        callback: async (data) => {
          await emailSettingsStore.addToAllowBlockList(data);
        },
        disable: actionNotAllowed(),
      });

      dialogsStore.openDialog(dialogConfig);
    };

    const importFromCsv = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        item: {
          downloadTemplateCallback: emailSettingsStore.downloadAllowBlockListExampleCsv,
          uploadCallback: async (data: File) => {
            await emailSettingsStore.importAllowBlockListFromCsv(data);
            await emailSettingsStore.getAllowBlockList();
          },
        },
        action: PhishingAction.DOWNLOAD_ALLOW_BLOCK_LIST_EXAMPLE_CSV,
        component: ImportCSVModal,
        width: ModalWidth.LARGE,
        callback: () => {},
        hideFooter: true,
        disable: actionNotAllowed(),
      });

      dialogsStore.openDialog(dialogConfig);
    };

    const onActionClick = (event: { item: AllowBlockListItem; action: PhishingAction }) => {
      dialogsStore.openDialog(
        confirmationDialogsConfigConstructor({
          action: event.action,
          item: {
            ...event.item,
          },
          callback: async () => {
            const method =
              event.action === PhishingAction.REMOVE_FROM_ALLOWLIST
                ? emailSettingsStore.removeFromAllowlist
                : emailSettingsStore.removeFromBlocklist;

            await method(event.item);
          },
          disable: actionNotAllowed(),
        })
      );
    };

    return {
      showApplyToChildWorkspaces,
      actionNotAllowed,
      loading,
      localValue,
      actions: [PhishingAction.REMOVE_FROM_ALLOWLIST],
      types: [
        EmailSecurityAllowBlockItemType.EMAIL,
        EmailSecurityAllowBlockItemType.DOMAIN,
        EmailSecurityAllowBlockItemType.IP,
        EmailSecurityAllowBlockItemType.IP_RANGE,
      ],
      lists: [AllowBlockListType.ALLOW, AllowBlockListType.BLOCK],
      PhishingAction,
      filterContext: FilterContext.ALLOW_BLOCK_LIST,
      allowBlockList,
      pages,
      total,
      pagination,
      onActionClick,
      addItemToAllowBlock,
      onPageChanged,
      localFilters,
      showClearFiltersButton,
      clearFilters,
      clearFiltersCallback,
      importFromCsv,
    };
  },
});
</script>

<style scoped lang="scss">
.allow-blocklist-tab-content {
  position: relative;
}
.allow-blocklist-tab-add-btn {
  position: absolute;
  top: -104px;
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(180px, 200px) minmax(160px, 200px) minmax(160px, 300px);
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
}

:deep(*) {
  .v-slide-group__wrapper {
    z-index: 40 !important;
  }
}
</style>
