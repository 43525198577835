import differenceWith from "lodash/differenceWith";
import unionWith from "lodash/unionWith";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import { defineStore } from "pinia";

export interface ISelection<T = any> {
  isAllPages: boolean;
  include: T[];
  exclude: T[];
}

export interface SelectorState<T = any> {
  totalItems: number;
  selection: ISelection<T>;
  allowAllPagesSelection: boolean;
}
export const defaultSelection = {
  isAllPages: false,
  include: [],
  exclude: [],
};

function updateSelectionWithNewItems<T>(oldItems: T[], newItems: T[], itemKey: keyof T) {
  return oldItems.map((item) => {
    const updatedItem = newItems.find(
      (newItem) => get(newItem, itemKey, "") === get(item, itemKey, "")
    );
    return updatedItem ? updatedItem : item;
  });
}

export const useSelectorStore = defineStore("selector", {
  state: (): SelectorState => ({
    selection: { ...defaultSelection },
    totalItems: 0,
    allowAllPagesSelection: true,
  }),
  actions: {
    selectAllPages() {
      this.selection = {
        isAllPages: true,
        include: [],
        exclude: [],
      };
    },
    deselectAllPages() {
      this.selection = { ...defaultSelection };
    },
    selectOneItem<T = any>(payload: { items: T[]; totalItems: number }) {
      if (this.selection.isAllPages) {
        this.selection = {
          isAllPages: true,
          include: [],
          exclude: differenceWith(this.selection.exclude, payload.items, isEqual),
        };
      } else {
        const includedItems = unionWith(this.selection.include, payload.items, isEqual);
        if (
          includedItems.length === payload.totalItems &&
          payload.totalItems > 0 &&
          this.allowAllPagesSelection
        ) {
          this.selection = {
            isAllPages: true,
            include: [],
            exclude: [],
          };
        } else {
          this.selection = {
            isAllPages: false,
            include: includedItems,
            exclude: [],
          };
        }
      }
    },
    deselectOneItem<T = any>(payload: { items: T[]; totalItems: number }) {
      const excludedItems = unionWith(this.selection.exclude, payload.items, isEqual);
      if (this.selection.isAllPages) {
        if (excludedItems.length === payload.totalItems) {
          this.selection = {
            isAllPages: false,
            include: [],
            exclude: [],
          };
          return;
        }
        this.selection = {
          isAllPages: true,
          include: [],
          exclude: excludedItems,
        };
      } else {
        this.selection = {
          isAllPages: false,
          include: differenceWith(this.selection.include, payload.items, isEqual),
          exclude: [],
        };
      }
    },
    /**
     * This mutation updates selection if some property of selected item is mutated (for example count of users changed).
     * @param state
     * @param newItems
     * @param itemKey
     */
    refreshSelection<T = any>({ newItems, itemKey }: { newItems: T[]; itemKey: keyof T }) {
      this.selection = {
        isAllPages: this.selection.isAllPages,
        include: updateSelectionWithNewItems(this.selection.include, newItems, itemKey),
        exclude: updateSelectionWithNewItems(this.selection.exclude, newItems, itemKey),
      };
    },
    setSelection<T = any>(selection: ISelection<T>) {
      this.selection = selection;
    },
  },
});
