<template>
  <v-skeleton-loader
    class="skeleton-loader--agent-deployment"
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.DEFAULT_TABLE"
  >
    <div class="d-flex justify-end">
      <v-btn
        class="channels-button pr-0 mb-4"
        variant="text"
        :ripple="false"
        @click="selectChannel()"
        color="primary"
      >
        <div class="body2">
          {{ $t(`devicesSettings.channels.${selectedChannel}`) }}
        </div>
        <v-icon class="ml-1" icon="$settings" color="primary" size="24"> </v-icon>
      </v-btn>
    </div>
    <table-wrapper>
      <v-data-table
        class="permissions-tab-table"
        v-model:expanded="expanded"
        :items="availableVersions"
        item-key="id"
        :group-by="groupBy"
        :show-expand="true"
        hide-default-header
        :items-per-page="-1"
        :hover="false"
        :headers="headers"
      >
        <template #headers>
          <tr>
            <th>
              {{ $t("devicesSettings.agentDeploymentTab.version") }}
            </th>
            <th>
              {{ $t("devicesSettings.agentDeploymentTab.released") }}
            </th>
            <th>
              {{ $t("devicesSettings.agentDeploymentTab.channel") }}
            </th>
            <th>
              {{ $t("devicesSettings.agentDeploymentTab.devices") }}
            </th>
            <th class="w-10"></th>
          </tr>
        </template>
        <template #group-header="{ toggleGroup, item, isGroupOpen }">
          <tr class="group-header">
            <th :colspan="5">
              <v-btn
                :icon="true"
                variant="plain"
                :ripple="false"
                @click="toggleGroup(item)"
                :ref="`${item.value.toLowerCase()}ExpandButton`"
              >
                <v-icon :class="{ rotated: isGroupOpen(item) }" icon="$chevronDown"> </v-icon>
              </v-btn>
              <span class="subtitle1">
                {{ $t(`devicesSettings.agentDeploymentTab.osTypes.${item?.value?.toLowerCase()}`) }}
              </span>
            </th>
          </tr>
        </template>
        <template #item="{ item, index, isExpanded, toggleExpand, internalItem }">
          <tr class="agent-deployment-table-row" :data-testid="index">
            <td>
              <div class="d-inline-flex align-center">
                <coro-icon class="coro-icon mr-4" icon-name="coronet"></coro-icon>
                <div>
                  <div>
                    <span class="subtitle1">{{ item.version }}</span>
                    <span class="body1"> ({{ item.marketingVersion }})</span>
                  </div>
                  <div class="d-inline-flex">
                    <span class="body2 text--grey">{{
                      $t("devicesSettings.agentDeploymentTab.releaseNotes")
                    }}</span>
                    <v-icon
                      class="ml-1"
                      :class="{ rotated: isExpanded(internalItem) }"
                      @click="toggleExpand(internalItem)"
                      icon="$chevronDown"
                    >
                    </v-icon>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <span class="body2">{{ getFormattedDateTime(item.releaseDate, "ll") }}</span>
            </td>
            <td>
              <span class="body2">
                {{ $t(`devicesSettings.agentDeploymentTab.channels.${item.channel}`) }}
              </span>
            </td>
            <td>
              <span class="body2">{{ item.installedOnDevices }}</span>
            </td>
            <td>
              <v-menu class="justify-end" offset-y bottom right>
                <template #activator="{ props }">
                  <v-btn
                    class="ml-auto"
                    rounded
                    color="primary"
                    data-testid="user-preview-action-button"
                    v-bind="props"
                  >
                    {{ $t("general.actions") }}
                    <v-icon class="ml-1 mt-1" icon="$triangle" size="8"> </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="action in actions"
                    :key="action"
                    :disabled="isActionDisabled(action)"
                    :data-testid="`agent-deployment-${kebabCase(action)}-action`"
                    @click="onActionClick(action, item)"
                  >
                    <v-list-item-title>
                      {{ $t(`devicesSettings.agentDeploymentTab.actions.${action}`) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template #expanded-row="{ item, columns }">
          <td :colspan="columns.length">
            <div class="mt-3 mb-3 ml-5">
              <div
                v-for="releaseNote in item.releaseNotes"
                :key="releaseNote"
                class="body2 ml-12 text-gray-neutral"
              >
                {{ releaseNote }}
              </div>
            </div>
          </td>
        </template>
        <template #bottom></template>
      </v-data-table>
    </table-wrapper>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { type ComponentPublicInstance, defineComponent, onMounted, type Ref, ref } from "vue";
import CoroIcon from "@/components/CoroIcon.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { AgentDeploymentAction } from "@/constants/devices";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import kebabCase from "lodash/kebabCase";
import capitalize from "lodash/capitalize";
import { type DeviceVersionItem, useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { storeToRefs } from "pinia";
import {
  componentDialogsConfigConstructor,
  copyToClipboard,
  getConvertedOSTypeForAWS,
  getDownloadLink,
  getFormattedDateTime,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useI18n } from "vue-i18n";
import { AdminUsersAction } from "@/constants/admin-accounts";
import CopyLinkModal from "@/components/modals/CopyLinkModal.vue";
import { ModalWidth } from "@/constants/modals";
import { useSnackbarStore } from "@/_store";
import { useDialogsStore } from "@/_store/dialogs.module";
import SelectChannelModal from "@/components/modals/SelectChannelModal.vue";

export default defineComponent({
  components: {
    CoroIcon,
    TableWrapper,
  },
  setup() {
    const osxExpandButton: Ref<null | ComponentPublicInstance<typeof HTMLElement>> = ref(null);
    const windowsExpandButton: Ref<null | ComponentPublicInstance<typeof HTMLElement>> = ref(null);
    const linuxExpandButton: Ref<null | ComponentPublicInstance<typeof HTMLElement>> = ref(null);
    const snackbarStore = useSnackbarStore();
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();
    const expanded = ref([]);
    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.WORKSPACE_MANAGEMENT,
      WorkspaceManagementScopeSections.DEVICES
    );
    const devicesSettingsStore = useDevicesSettingsStore();
    const { availableVersions, downloadClientPageLink, showSkeletonLoader, selectedChannel } =
      storeToRefs(devicesSettingsStore);

    const selectChannel = () => {
      dialogsStore.openDialog(
        componentDialogsConfigConstructor({
          action: AgentDeploymentAction.SELECT_CHANNEL,
          callback: devicesSettingsStore.selectChannel,
          component: SelectChannelModal,
          item: selectedChannel.value,
          width: ModalWidth.LARGE,
        })
      );
    };

    async function onActionClick(action: AgentDeploymentAction, item: DeviceVersionItem) {
      const downloadLink = await createDownloadLink(item);
      switch (action) {
        case AgentDeploymentAction.COPY_LINK:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              item: { inviteLink: downloadLink },
              action: AdminUsersAction.COPY_INVITE_LINK,
              component: CopyLinkModal,
              width: ModalWidth.LARGE,
              disable: actionNotAllowed,
              callback: () => {
                copyToClipboard(downloadLink);
                snackbarStore.addGenericSuccess(
                  i18n.t("snackbar.messages.general.copiedToClipboard")
                );
              },
            })
          );
          break;
        case AgentDeploymentAction.DOWNLOAD:
          await handleDownloadAction(downloadLink);
          break;
      }
    }

    const isActionDisabled = (action: AgentDeploymentAction) => {
      return actionNotAllowed && action === AgentDeploymentAction.DOWNLOAD;
    };

    async function createDownloadLink(item: DeviceVersionItem) {
      const url: { [key in "win" | "macos" | "linux"]: string } = await getDownloadLink({
        id: downloadClientPageLink.value.split("/")[4],
        os: getConvertedOSTypeForAWS(item.osType),
        version: item.version,
      });
      const convertedOsType = getConvertedOSTypeForAWS(item.osType);
      return url[convertedOsType];
    }

    async function handleDownloadAction(downloadLink: string) {
      const linkEl = document.createElement("a");
      linkEl.href = downloadLink;
      document.body.appendChild(linkEl);
      linkEl.click();
      document.body.removeChild(linkEl);
    }

    onMounted(async () => {
      await devicesSettingsStore.getDownloadClientPageLink();
      await devicesSettingsStore.getAvailableVersions();
      if (windowsExpandButton.value) {
        windowsExpandButton.value.$el.click();
      }
      if (osxExpandButton.value) {
        osxExpandButton.value.$el.click();
      }
      if (linuxExpandButton.value) {
        linuxExpandButton.value.$el.click();
      }
    });

    return {
      osxExpandButton,
      windowsExpandButton,
      linuxExpandButton,
      groupBy: [
        {
          key: "osType",
        },
      ],
      headers: [
        { key: "data-table-expand", groupable: false },
        {
          title: i18n.t("devicesSettings.agentDeploymentTab.version"),
          key: "version",
          sortable: false,
        },
        {
          title: i18n.t("devicesSettings.agentDeploymentTab.released"),
          key: "releaseDate",
          sortable: false,
        },
        {
          title: i18n.t("devicesSettings.agentDeploymentTab.channel"),
          key: "isStable",
          sortable: false,
        },
        {
          title: i18n.t("devicesSettings.agentDeploymentTab.devices"),
          key: "installedOnDevices",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
      actionNotAllowed,
      selectedChannel,
      availableVersions,
      showSkeletonLoader,
      expanded,
      kebabCase,
      capitalize,
      getFormattedDateTime,
      coronetSkeletonLoaderTypes,
      actions: [AgentDeploymentAction.COPY_LINK, AgentDeploymentAction.DOWNLOAD],
      selectChannel,
      onActionClick,
      isActionDisabled,
    };
  },
});
</script>

<style lang="scss" scoped>
.group-header {
  background: rgb(var(--v-theme-indigo-pale));
  border-bottom: 0 !important;
  position: sticky;
  top: 0;
  z-index: 2;
  padding-left: 4px !important;

  .icon-chevron:before {
    color: rgb(var(--v-theme-primary)) !important;
  }
}
.channels-button {
  text-transform: unset !important;
  background: transparent !important;
}

.agent-deployment-table-row {
  height: 80px;
}

.coro-icon {
  height: 40px;
  width: 40px;
}
</style>
