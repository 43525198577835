import isEmpty from "lodash/isEmpty";
import api from "@/_helpers/api";
import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";

export interface PrivacySensitiveDataSettings {
  monitorPII: boolean;
  monitorPCI: boolean;
  monitorPHI: boolean;
  monitorNPI: boolean;
  monitorNonPublicData: boolean;
  monitorPersonalData: boolean;
  monitorHealthData: boolean;
  monitorCreditCardData: boolean;
  wizardParameters: {
    locations: string[];
    industries: string[];
    isDefault: boolean;
  };
}

interface PrivacySensitiveDataState {
  settings: PrivacySensitiveDataSettings;
  showSkeletonLoader: boolean;
}

export const defaultPrivacySensitiveDataState = {
  settings: {
    wizardParameters: {
      locations: [],
      industries: [],
      isDefault: false,
    },
    monitorPII: false,
    monitorPCI: false,
    monitorPHI: false,
    monitorNPI: false,
    monitorNonPublicData: false,
    monitorPersonalData: false,
    monitorHealthData: false,
    monitorCreditCardData: false,
  },
  showSkeletonLoader: false,
};

export const usePrivacySensitiveDataStore = defineStore("privacySensitiveData", {
  state: (): PrivacySensitiveDataState => ({
    ...defaultPrivacySensitiveDataState,
  }),
  getters: {
    deviceSettingsDisabled(state) {
      const deviceSettings = state.settings;
      return (
        !isEmpty(deviceSettings) &&
        !(
          deviceSettings.monitorNPI ||
          deviceSettings.monitorPCI ||
          deviceSettings.monitorPHI ||
          deviceSettings.monitorPII ||
          deviceSettings.monitorNonPublicData ||
          deviceSettings.monitorPersonalData ||
          deviceSettings.monitorHealthData ||
          deviceSettings.monitorCreditCardData
        )
      );
    },
  },
  actions: {
    async getSettings(wizardConfig = {}) {
      this.showSkeletonLoader = true;

      const request = {
        ...api.endpointDataGovernanceSettings,
        params: wizardConfig,
      };

      try {
        const { data } = await axiosInstance.request(request);

        this.settings = data;
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
    },
    async updateSettings(newSettings: PrivacySensitiveDataSettings) {
      const request = {
        ...api.endpointDataGovernanceSettings,
        method: "put",
        data: newSettings,
      };

      try {
        await axiosInstance.request(request);

        this.settings = newSettings;
        await this.getSettings(newSettings.wizardParameters);
      } catch (error) {
        console.error(error);
      }
    },
  },
});
