import { defineStore } from "pinia";
import type { AxiosError, AxiosRequestConfig } from "axios";
import api from "@/_helpers/api";
import router from "@/_helpers/router";
import { axiosInstance } from "@/plugins/https";
import type {
  GlobalRolePermissions,
  MspPortalPermissionsMap,
  WorkspaceRolePermissions,
} from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { AccountErrors, MfaStatus } from "@/constants/account";
import { extractCoroSubdomain, parseJwt, resetPersistedStores } from "@/_helpers/utils";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import type { Service } from "@/constants/cloud-apps";
import type { WorkspaceType } from "@/constants/workplaces";
import { WorkspaceLocale } from "@/constants/workplaces";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import { i18n } from "@/plugins/i18n";
import { useMyAccountStore } from "@/_store/my-account.module";
import { RouteName } from "@/constants/routes";
import { camelCase } from "lodash";
import { useMfaStore } from "@/_store/mfa.module";

export const appLogoDefaultPath = "/images/logos/coro-logo-small.svg";

export interface LoginData {
  token: string;
  refreshToken: string;
  onboardingService: Service;
  mfaConfig: MfaStatus;
  multipleWorkspaces: boolean;
  onboardingCompleted: boolean;
  hasUserChangedPassword: boolean;
  applications: string[];
}

type AccountState = {
  error: string;
  requestInProgress: boolean;
  requestCompleted: boolean;
  email: string;
  token: string;
  workplace: string;
  languageCode: WorkspaceLocale;
  customerName: string;
  refreshToken: string;
  onboardingCompleted: boolean;
  service: string;
  inviteAccepted: boolean;
  hasUserChangedPassword: boolean;
  dashboardGuideShown: boolean;
  controlPanelGuideShown: boolean;
  psaEnabled: boolean;
  isDemoModeEnabled: boolean;
  workspaceType: WorkspaceType;
  appLogo: string;
  socialLogin: boolean;
  domain: string;
  brandingAlias: string;
  brandColor: string;
  utmParams: object;
  supportEnabled: boolean;
  isCoronetWorkspace: boolean;
  // null means that user logged in the workspace is not the workspace admin thus he has some global role
  currentWorkspacePermissions: WorkspaceRolePermissions | null;
  globalWorkspacePermissions: GlobalRolePermissions | null;
  mspPortalPermissionsMap: MspPortalPermissionsMap;
  googleClientId: string;
  showDisabledModules: boolean;
  parentWorkspaceName?: string;
};

export interface AccountStateVue2 {
  account: AccountState;
}

const defaultAccountState: AccountState = {
  error: "",
  requestInProgress: false,
  requestCompleted: false,
  email: "",
  token: "",
  workplace: "",
  customerName: "",
  refreshToken: "",
  onboardingCompleted: false,
  service: "",
  inviteAccepted: false,
  hasUserChangedPassword: false, // have user EVER changed password?
  dashboardGuideShown: true,
  controlPanelGuideShown: true,
  psaEnabled: false,
  isDemoModeEnabled: false,
  workspaceType: "" as WorkspaceType,
  appLogo: appLogoDefaultPath,
  socialLogin: false,
  domain: "",
  brandingAlias: "",
  brandColor: "",
  utmParams: {},
  supportEnabled: true,
  isCoronetWorkspace: false,
  currentWorkspacePermissions: null,
  globalWorkspacePermissions: null,
  googleClientId: "",
  showDisabledModules: false,
  mspPortalPermissionsMap: {},
  languageCode: WorkspaceLocale.EN_US,
};

export const useAccountStore = defineStore("account", {
  state: (): AccountStateVue2 => cloneDeep({ account: cloneDeep(defaultAccountState) }),
  persist: true,
  getters: {
    logged(state) {
      return Boolean(state.account.email && state.account.token && state.account.refreshToken);
    },
    isGlobalAdmin(state) {
      return !isEmpty(state.account.globalWorkspacePermissions);
    },
    hasMspPermissions(state) {
      return !isEmpty(state.account.mspPortalPermissionsMap);
    },
    displayLanguage(state) {
      const routePreferredLocale = router.currentRoute.value.meta.preferredLocale;
      if (routePreferredLocale) {
        return routePreferredLocale;
      }
      const myAccountStore = useMyAccountStore();
      const preferredLanguage = myAccountStore.myAccount.profileData.preferredLanguageCode;
      return preferredLanguage || state.account.languageCode || WorkspaceLocale.EN_US;
    },
  },
  actions: {
    async login(credentials: { userName: string; password: string }) {
      const brandingAlias = this.account.brandingAlias;
      const params = brandingAlias && { brandingAlias };
      const request: AxiosRequestConfig = {
        ...api.login,
        data: credentials,
        params,
      };
      this.account.requestInProgress = true;
      grecaptcha.ready(async () => {
        try {
          const token = await grecaptcha.execute("6Le7dXgUAAAAAKD_vdTFFmWPA6-4wTwNsU2WpTC6", {
            action: "action_name",
          });
          request.headers = {
            "g-recaptcha-response": token,
          };
          const { data } = await axiosInstance.request(request);
          await this.processLoginData(data);
          this.account.requestInProgress = false;
        } catch (e: any) {
          if (!e.response) {
            this.account.error = AccountErrors.GENERIC;
          } else if (e.response.status === 403) {
            this.account.error = camelCase(e.response.data.message);
          }
          this.account.requestInProgress = false;
          console.error(e);
        }
      });
    },
    async loginViaSSO(token: string) {
      const request = {
        ...api.loginViaSSO(token),
      };

      this.setError("");
      this.setRequestInProgress(true);

      try {
        const res = await axiosInstance.request(request);
        await this.processLoginData(res.data);
      } catch (e) {
        const error = e as AxiosError<{ message?: string }>;
        if (!error.response || !error.response.data.message) {
          this.setError(i18n.global.t(`sso.errors.${AccountErrors.GENERIC}`));
        } else {
          this.setError(error.response.data.message);
        }
      } finally {
        this.setRequestInProgress(false);
      }
    },

    async signUp(service: Service) {
      try {
        const utmParams = this.account.utmParams;
        const request = {
          ...api.signUp(service, utmParams),
        };
        const response = await axiosInstance.request(request);

        // Redirect to the URL from the response
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async processLoginData(payload: LoginData) {
      const workspacesStore = useWorkspacesStore();
      const hasNavigationFromEmail = false;
      const {
        hasUserChangedPassword,
        multipleWorkspaces,
        onboardingCompleted,
        onboardingService: service,
        refreshToken,
        token,
        mfaConfig,
      } = payload;
      const { sub } = parseJwt(token) ?? {};
      const email = sub ?? this.account.email;
      this.$patch({ account: { email } });
      this.account.utmParams = {};

      if (mfaConfig === MfaStatus.TOTP) {
        const mfaStore = useMfaStore();
        mfaStore.$patch({
          mfaFlowToken: token,
        });
        await router.push({ name: RouteName.MFA_VERIFICATION });
        return;
      }

      if (multipleWorkspaces || hasNavigationFromEmail) {
        this.$patch({
          account: {
            token,
            refreshToken,
            onboardingCompleted,
            hasUserChangedPassword,
            service,
          },
        });
        await this.getProfileInformation(onboardingCompleted, multipleWorkspaces, service);
        this.account.requestInProgress = false;
      } else {
        const workspacesRequest = {
          ...api.workplaces({
            name: "",
            type: "",
            page: 0,
            pageSize: 25,
          }),
          headers: {
            Authorization: `Bearer ${token}`, //the token is a variable which holds the token
          },
          method: "get",
        };

        try {
          const { data } = await axiosInstance.request(workspacesRequest);
          const workspace = data.items[0];
          this.$patch({
            account: {
              token,
              refreshToken,
              onboardingCompleted,
              hasUserChangedPassword,
              service,
              workplace: workspace.workspaceId,
            },
          });
          await this.getProfileInformation(onboardingCompleted, multipleWorkspaces, service);
          await workspacesStore.updateWorkspaceAndPermissions(workspace.workspaceId);
        } catch (e) {
          console.error(e);
        }
      }
    },
    async getProfileInformation(
      onboardingCompleted: boolean,
      multipleWorkspaces: boolean,
      service: Service
    ) {
      const myAccountStore = useMyAccountStore();
      try {
        await myAccountStore.getProfileData();
        if (!onboardingCompleted) {
          await router.push({ name: RouteName.SIGN_UP, query: { isNextStep: "true", service } });
        } else {
          if (multipleWorkspaces) {
            await router.push({ name: RouteName.WORKSPACES });
          } else {
            await router.push({ name: RouteName.DASHBOARD });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async socialLogin(service: Service) {
      const brandingAlias = this.account.brandingAlias;
      const request = {
        ...api.socialLogin(service, brandingAlias),
      };
      this.account.service = service;
      try {
        const { data } = await axiosInstance.request(request);
        window.location.href = data.redirectUrl;
      } catch (e) {
        console.error(e);
      }
    },
    async exchangeAuthCode(authCode: string, workspace: string) {
      try {
        const request = {
          ...api.exchangeAuthCode(),
          data: {
            authCode,
          },
        };
        const { data } = await axiosInstance.request(request);
        const payload = {
          ...data,
          workspace,
        };
        this.loginFromPortal(payload);
      } catch (e) {
        this.account.error = AccountErrors.GENERIC;
        console.error(e);
      }
    },
    loginFromPortal(payload: {
      hasUserChangedPassword: boolean;
      onboardingCompleted: boolean;
      onboardingService: Service;
      refreshToken: string;
      token: string;
      workspace: string;
    }) {
      const {
        hasUserChangedPassword,
        onboardingCompleted,
        onboardingService: service,
        refreshToken,
        token,
      } = payload;
      const { sub } = parseJwt(token) ?? {};
      const email = sub ?? this.account.email;
      this.$patch({ account: { email } });

      const workspaceRequest = {
        ...api.workplaces({
          name: payload.workspace,
          type: "",
          page: 0,
          pageSize: 25,
        }),
        headers: {
          Authorization: `Bearer ${token}`, //the token is a variable which holds the token
        },
        method: "get",
      };
      axiosInstance.request(workspaceRequest).then((response) => {
        this.$patch({
          account: {
            token,
            refreshToken,
            onboardingCompleted,
            hasUserChangedPassword,
            service,
          },
        });
        const workspaceId = get(response, "data.items[0].workspaceId", null);
        this.account.workplace = workspaceId;
        this.account.customerName = get(response, "data.items[0].name", null);
        this.account.workspaceType = get(response, "data.items[0].type", null);
        this.setAppLogo(get(response, "data.items[0].branding.headerIconUrl", null));
        this.setBrandColor(get(response, "data.items[0].branding.brandColor", null));
        this.account.domain = get(response, "data.items[0].domain", null);
        this.account.supportEnabled = get(response, "data.items[0].supportEnabled", false);
        this.setIsCoronetWorkspace(get(response, "data.items[0].isCoronetWorkspace", false));
        this.account.requestInProgress = false;
        this.account.languageCode = get(
          response,
          "data.items[0].languageCode",
          WorkspaceLocale.EN_US
        );
        localStorage.setItem("report-navigation", `reportNav${Math.random()}`);
        router.push({ name: RouteName.DASHBOARD, query: { workspaceId } }).catch(() => {});
      });
    },
    async getRefreshToken() {
      return axiosInstance.request(api.refreshToken());
    },
    async logout() {
      try {
        await axiosInstance.request(api.logout());
      } catch (e) {
        console.error(e);
      } finally {
        const subdomain = extractCoroSubdomain(window.location.hostname);
        subdomain ? this.resetStateWithoutBranding() : this.$reset();
        resetPersistedStores();
        useDialogsStore().closeDialog();
        localStorage.setItem("logout-event", `logout${Math.random()}`);
        await router.push({ name: RouteName.LOGIN });
      }
    },
    async forgotPassword(payload: { email: string; showSnackbar?: boolean }) {
      const { email, showSnackbar } = payload;
      const request = {
        ...api.forgotPassword(email, this.account.brandingAlias),
      };
      try {
        await axiosInstance.request(request);
        if (showSnackbar) {
          useSnackbarStore().addGenericSuccess(
            i18n.global.t("snackbar.messages.resetPassword.linkSent", { email })
          );
        }
      } catch (e) {
        console.error(e);
        if (!(e as AxiosError).response) {
          this.account.error = AccountErrors.GENERIC;
        } else if ((e as AxiosError).response?.status === 404) {
          this.account.error = AccountErrors.EMAIL_NOT_FOUND;
        }
      }
    },
    async resetPassword(data: { token: string; newPassword: string }) {
      this.setRequestCompleted(false);
      this.setRequestInProgress(true);

      try {
        await axiosInstance.request({ ...api.resetPassword, data });
        this.setRequestCompleted(true);
      } catch (e) {
        console.error(e);

        const error = e as AxiosError<{ message?: string }>;
        if (!error.response) {
          this.account.error = AccountErrors.GENERIC;
          return;
        }

        const status = error.response?.status;
        const message = error.response?.data?.message;

        if (status === 400) {
          switch (message) {
            case "Reset password link is expired or not valid.":
              this.account.error = AccountErrors.RESET_LINK_INVALID;
              break;
            case "Password should be min 8 symbols and contain uppercase, numeric and special character.":
              this.account.error = AccountErrors.WEAK_PASSWORD;
              break;
            default:
              this.account.error = AccountErrors.PASSWORD_SHOULD_BE_DIFFERENT;
              break;
          }
        }
      } finally {
        this.setRequestInProgress(false);
      }
    },
    async requestBrandingInfo(alias: string) {
      const request = {
        ...api.getBranding(alias),
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.account.brandingAlias = alias;
        this.setAppLogo(data.headerIconUrl);
        this.setBrandColor(data.brandColor);
      } catch {
        this.account.brandingAlias = "";
        if (!this.logged) {
          this.setAppLogo(appLogoDefaultPath);
          this.setBrandColor("");
        }
      }
    },
    setWorkspace(workplace: string) {
      this.account.workplace = workplace;
    },
    setError(value: string) {
      this.account.error = value;
    },
    setRequestInProgress(value: boolean) {
      this.account.requestInProgress = value;
    },
    setRequestCompleted(value: boolean) {
      this.account.requestCompleted = value;
    },
    setCustomerName(customerName: string) {
      this.account.customerName = customerName;
    },
    setWorkspaceType(payload: WorkspaceType) {
      this.account.workspaceType = payload;
    },
    setAppLogo(logo?: string) {
      if (logo) {
        this.account.appLogo = logo;
      } else {
        this.account.appLogo = appLogoDefaultPath;
      }
    },
    setDomain(domain: string) {
      this.account.domain = domain;
    },
    setBrandColor(color?: string) {
      this.account.brandColor = color ? color : "";
    },
    setSupportEnabled(supportEnabled: boolean) {
      this.account.supportEnabled = supportEnabled;
    },
    setIsCoronetWorkspace(isCoronetWorkspace: boolean) {
      this.account.isCoronetWorkspace = isCoronetWorkspace;
    },
    setPsaEnabled(payload: boolean) {
      this.account.psaEnabled = payload;
    },
    setShowDisabledModules(payload: boolean) {
      this.account.showDisabledModules = payload;
    },
    setMspPortalPermissionsMap(payload: MspPortalPermissionsMap) {
      this.account.mspPortalPermissionsMap = { ...payload };
    },
    setGlobalPermissions(payload: GlobalRolePermissions | null) {
      this.account.globalWorkspacePermissions = payload;
    },
    setToken(payload: string) {
      this.account.token = payload;
    },
    setUtmParams(payload: object) {
      this.account.utmParams = payload;
    },
    setService(service: Service) {
      this.account.service = service;
    },
    resetStateWithoutBranding() {
      // Preserve specified fields
      const { brandingAlias, brandColor, appLogo } = this.account;
      this.account = {
        ...cloneDeep(defaultAccountState),
        brandingAlias,
        brandColor,
        appLogo,
      };
    },
    async completeServiceConnection(service: Service) {
      const cloudAppsModule = useCloudAppsStore();
      const request = {
        ...api.completeServiceConnection(),
        params: {
          service,
        },
      };

      try {
        await axiosInstance.request(request);
        await cloudAppsModule.getServices();
      } catch (error) {
        console.error(error);
      }
    },
    async fetchGoogleClientId() {
      const request = {
        ...api.fetchGoogleClientId(),
      };

      try {
        const response = await axiosInstance.request(request);

        this.account.googleClientId = response.data.clientId;
      } catch (error) {
        console.error(error);
      }
    },
    async completePermissionsGranting(service: Service) {
      const cloudAppsModule = useCloudAppsStore();
      const request = {
        ...api.completeServiceConnection(),
        params: {
          service,
        },
      };

      try {
        await axiosInstance.request(request);
        await cloudAppsModule.getServices();
      } catch (error) {
        console.error(error);
      }
    },
    async completeSignup(languageCode?: WorkspaceLocale) {
      try {
        const request = {
          ...api.completeOnboarding,
          data: {
            languageCode,
          },
        };
        await axiosInstance.request(request);
        this.account.onboardingCompleted = true;
        this.account.utmParams = {};
        await router.push({ name: RouteName.DASHBOARD });
      } catch (err) {
        console.error(err);
      }
    },
    setCurrentWorkspacePermissions(permissions: WorkspaceRolePermissions | null) {
      this.account.currentWorkspacePermissions = permissions;
    },
  },
});
