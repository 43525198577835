<template>
  <div class="d-flex align-center justify-space-between w-100">
    <div class="d-flex align-center justify-center">
      <slot name="prepend">
        <v-icon
          v-if="hasSorter"
          icon="$sortArrow"
          size="20"
          class="cursor-pointer"
          :class="{ 'sort-icon-rotated': isAsc }"
          @click="onSortIconClick"
        />
        <v-menu>
          <template v-slot:activator="{ props }">
            <div class="coro-link" v-bind="props">
              {{ minItem }}-{{ maxItem }} {{ $t("general.of") }} {{ totalItems }}
            </div>
          </template>

          <v-list>
            <v-list-subheader :title="$t('general.itemsPerPage')" />
            <v-list-item
              v-for="(item, index) in paginationOptions"
              :key="index"
              :active="item.value === pagination.pageSize"
              @click="onPageSizeChange(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </slot>
      <div class="ml-3" v-if="showPagination">
        <v-btn
          icon="$firstPage"
          class="navigation-btn"
          :ripple="false"
          size="x-small"
          variant="plain"
          color="primary"
          :disabled="disableFirstPageBtn"
          @click="selectFirstPage()"
        />
        <v-btn
          icon="$chevronLeft"
          :ripple="false"
          class="navigation-btn"
          size="x-small"
          variant="plain"
          color="primary"
          :disabled="disablePreviousPageBtn"
          @click="onPreviousPage"
        />
        <v-btn
          icon="$chevronRight"
          :ripple="false"
          class="navigation-btn"
          size="x-small"
          variant="plain"
          color="primary"
          :disabled="disableNextPageBtn"
          @click="onNextPage"
        />
        <v-btn
          icon="$lastPage"
          :ripple="false"
          class="navigation-btn"
          size="x-small"
          variant="plain"
          color="primary"
          :disabled="disableLastPageBtn"
          @click="selectLastPage"
        />
      </div>
    </div>
    <div class="mr-1">
      <slot name="append">
        <v-menu v-if="hasSorter">
          <template v-slot:activator="{ props }">
            <v-icon icon="$sortOption" v-bind="props" size="30" />
          </template>

          <v-list>
            <v-list-subheader :title="$t('general.sortBy')" />
            <template v-for="option in sortingOptions" :key="option.property">
              <v-list-item
                :data-testid="`coro-sorter-${kebabCase(option.property)}-item`"
                @click="onSortPropertyChange(option.property)"
                :active="option.property === sortProperty"
              >
                <v-list-item-title>
                  {{ option.displayName }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref, toRefs } from "vue";
import type { Pagination } from "@/types";
import { paginationOptions } from "@/constants/table";
import kebabCase from "lodash/kebabCase";
export enum SortOrders {
  ASC = "asc",
  DESC = "desc",
}

export interface SortObject {
  property: string;
  direction: string;
}

export interface SortingOptions {
  displayName: string;
  property: string;
}

export default defineComponent({
  props: {
    hasSorter: {
      type: Boolean,
      default: false,
    },
    defaultSortProperty: {
      type: String,
      default: "",
    },
    sortingOptions: {
      type: Array as PropType<Array<SortingOptions>>,
      default: () => [],
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["pagination-change", "sorting-change"],
  setup(props, { emit }) {
    const { items, pagination, totalItems } = toRefs(props);
    const totalPages = computed(() => Math.ceil(totalItems.value / pagination.value.pageSize));
    const disablePreviousPageBtn = computed(() => pagination.value.page === 0);
    const disableNextPageBtn = computed(() => pagination.value.page === totalPages.value - 1);
    const page = computed(() => pagination.value.page);
    const maxItem = computed(() => {
      return disableNextPageBtn.value ? totalItems.value : (page.value + 1) * items.value.length;
    });
    const isAsc = ref(true);
    const sortProperty = ref(props.defaultSortProperty);
    const minItem = computed(
      () => (page.value + 1) * pagination.value.pageSize - props.pagination.pageSize + 1
    );
    const disableFirstPageBtn = computed(() => page.value === 0);
    const disableLastPageBtn = computed(() => page.value + 1 === totalPages.value);

    const onSortIconClick = () => {
      isAsc.value = !isAsc.value;
      const direction = isAsc.value ? SortOrders.ASC : SortOrders.DESC;
      emit("sorting-change", { direction, property: sortProperty.value });
    };

    const onSortPropertyChange = (property: string) => {
      const direction = isAsc.value ? SortOrders.ASC : SortOrders.DESC;
      sortProperty.value = property;
      emit("sorting-change", { direction, property });
    };

    const onNextPage = () => {
      emit("pagination-change", {
        page: page.value + 1,
        pageSize: pagination.value.pageSize,
      });
    };

    const onPreviousPage = () => {
      emit("pagination-change", {
        page: page.value - 1,
        pageSize: pagination.value.pageSize,
      });
    };

    const onPageSizeChange = (pageSize: number) => {
      emit("pagination-change", {
        page: 0,
        pageSize: pageSize,
      });
    };

    const selectLastPage = () => {
      emit("pagination-change", {
        page: totalPages.value - 1,
        pageSize: pagination.value.pageSize,
      });
    };

    const selectFirstPage = () => {
      emit("pagination-change", {
        page: 0,
        pageSize: pagination.value.pageSize,
      });
    };

    return {
      onNextPage,
      onPreviousPage,
      disablePreviousPageBtn,
      disableNextPageBtn,
      page,
      maxItem,
      minItem,
      totalPages,
      paginationOptions,
      onPageSizeChange,
      selectFirstPage,
      selectLastPage,
      isAsc,
      sortOrders: SortOrders,
      onSortIconClick,
      kebabCase,
      sortProperty,
      onSortPropertyChange,
      disableLastPageBtn,
      disableFirstPageBtn,
    };
  },
});
</script>

<style scoped lang="scss">
.coro-link {
  color: rgb(var(--v-theme-indigo-medium)) !important;
}
.navigation-btn {
  width: 25px !important;
}
.sort-icon-rotated {
  transform: rotate(180deg);
}
</style>
