<template>
  <div v-if="showFullDetails" class="mt-5">
    <h6 class="headline6 mb-4">
      {{ $t("ticketDetails.fullDetails.title") }}
    </h6>
    <div class="full-details-wrapper">
      <v-data-table-server
        :items="locations"
        :single-expand="singleExpand"
        v-model:expanded="expandedFullDetails"
        hide-default-footer
        item-value="uniqueId"
        show-expand
        fixed-header
        class="countries-table"
        :items-length="locations?.length"
      >
        <template #headers>
          <tr>
            <th></th>
            <th class="text-left country-column">
              {{ $t("ticketDetails.fullDetails.abnormalDataManipulation.countryOrIP") }}
            </th>
            <th class="text-left">
              {{ $t("ticketDetails.fullDetails.abnormalDataManipulation.host") }}
            </th>
          </tr>
        </template>
        <template #item="{ item, toggleExpand, isExpanded, internalItem, index }">
          <tr :id="`${index}`" class="full-details-table-row">
            <td class="w-10">
              <v-icon
                v-if="shouldHaveExpander(item)"
                class="ml-1 text-primary"
                size="24"
                icon="$dropdown"
                :class="{ rotated: isExpanded(internalItem) }"
                @click="toggleExpand(internalItem)"
              >
              </v-icon>
            </td>
            <td>
              <div class="subtitle2">{{ item.country }}</div>
              <span class="body1" :class="getThreatLevelColor(item.threatLevel)">
                {{ item.ip }}
              </span>
            </td>
            <td>
              <div class="hostname-column body1">
                <v-tooltip open-delay="300" location="top">
                  <template #activator="{ props }">
                    <div class="body2 hostname-column" v-bind="props">
                      {{ item.host }}
                    </div>
                  </template>
                  {{ item.host }}
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>
        <template #expanded-row="{ columns, item }">
          <tr>
            <td></td>
            <td :colspan="columns.length - 1" class="pa-4">
              <div v-if="item.orgName">
                <span class="subtitle2">{{
                  $t(`ticketDetails.fullDetails.anomalyDetection.orgName`)
                }}</span>
                <div class="body2">
                  {{ item.orgName }}
                </div>
              </div>
              <div v-if="item.threatDetectionDate" class="mt-4">
                <span class="subtitle2">{{
                  $t(`ticketDetails.fullDetails.anomalyDetection.threatType`)
                }}</span>
                <div class="body2">
                  {{ getThreatType(item.threatTypes) }}
                </div>
                <div class="caption">
                  {{ $t(`ticketDetails.fullDetails.anomalyDetection.lastDetected`) }}
                  {{ getFormattedDateTime(item.threatDetectionDate, "MMM DD, YYYY") }}
                </div>
              </div>
              <div v-if="item.proxyType" class="mt-4">
                <span class="subtitle2">{{
                  $t(`ticketDetails.fullDetails.anomalyDetection.proxy`)
                }}</span>
                <div class="body2">
                  {{ item.proxyType }}
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table-server>
      <div class="subtitle1 mt-4 mb-4">
        {{ $t("ticketDetails.fullDetails.abnormalDataManipulation.files") }}
      </div>
      <v-data-table-server
        :items="folders"
        :single-expand="false"
        v-model:expanded="expanded"
        :loading="!foldersLoaded"
        :items-length="foldersTotal"
        disable-pagination
        hide-default-footer
        item-value="id"
        show-expand
        fixed-header
        class="folder-table"
      >
        <template #headers>
          <tr>
            <th class="w-10"></th>
            <th class="w-80">
              {{ $t("ticketDetails.fullDetails.abnormalDataManipulation.folder") }}
            </th>
            <th class="text-right">
              {{ $t("ticketDetails.fullDetails.abnormalDataManipulation.filesAffected") }}
            </th>
          </tr>
        </template>
        <template #item="{ item, index, isExpanded, toggleExpand, internalItem }">
          <tr :id="`${index}`">
            <td class="w-10">
              <v-icon
                class="ml-1 text-primary"
                :class="{ rotated: isExpanded(internalItem) }"
                @click="toggleExpand(internalItem)"
                size="24"
                icon="$dropdown"
              >
              </v-icon>
            </td>
            <td class="w-80 body2">
              <div class="word-break-all white-space-normal">
                {{ item.path }}
              </div>
            </td>
            <td class="body2 text-right">
              {{ item.filesAffected }}
            </td>
          </tr>
        </template>
        <template #expanded-row="{ columns, item, index }">
          <td class="w-10"></td>
          <td
            :colspan="columns.length"
            class="pl-4 pr-5 py-2 word-break-all white-space-normal expandable-content"
          >
            <div :id="`${index}`" :key="index">
              <div v-for="file in getFiles(item)" :key="file.fileName">
                <div class="d-flex justify-space-between pb-3 pt-3">
                  <div class="d-flex">
                    <div class="body2 text--semibold mr-2">{{ file.fileName }}</div>
                  </div>
                  <div class="caption">{{ file.mimeType }}</div>
                </div>
                <v-divider></v-divider>
              </div>
            </div>
            <div v-if="item.filesAffected > 10">
              <div role="button" class="coro-link text-center mt-2" @click="viewAllFiles(item)">
                {{ $t("general.seeNMore", { n: item.filesAffected - 10 }) }}
              </div>
            </div>
          </td>
        </template>
      </v-data-table-server>
      <div v-if="filesToLoad > 0" class="load-more-section">
        <div class="coro-link cursor-pointer" @click="loadMoreItems()">
          {{ $t("ticketDetails.plusNFiles", { n: filesToLoad }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  type AbnormalDataManipulationFile,
  type AbnormalDataManipulationFolder,
} from "@/_store/tickets/ticket.module";
import { storeToRefs } from "pinia";
import {
  computed,
  defineComponent,
  onMounted,
  type PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { getFormattedDateTime } from "@/_helpers/utils";
import { RouteName } from "@/constants/routes";
import type { AnomalyDetection } from "@/_store/tickets/tickets.module";
import { difference, uniqueId } from "lodash";
import { useRouter } from "vue-router";
import { getThreatLevelColor } from "@/_store/tickets/adapters";
import {
  type MspTicketDetails,
  useMspTicketsStore,
} from "@/_store/msp/tickets/msp-tickets.module.ts";

export default defineComponent({
  props: {
    ticket: {
      type: Object as PropType<MspTicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const ticketStore = useMspTicketsStore();
    const { fullDetails } = storeToRefs(ticketStore);
    const { folders, foldersLoaded, foldersTotal } = toRefs(fullDetails.value);
    const page = ref(0);
    const expanded = ref<string[]>([]);
    const expandedFullDetails = ref<string[]>([]);
    const singleExpand = ref(false);
    const fileValuesMap = reactive<{ [path: string]: AbnormalDataManipulationFile[] }>({});
    const locations = computed(() => {
      return props.ticket.sections?.anomalyDetectionMetaData?.records
        ? props.ticket.sections?.anomalyDetectionMetaData?.records.map((item) => ({
            ...item,
            uniqueId: uniqueId(),
          }))
        : [];
    });
    const showFullDetails = computed(() => locations.value?.length || folders.value.length);

    const mitreData = computed(() => {
      return props.ticket.sections?.edrMetaData?.mitre ?? [];
    });

    const showExpander = computed(() => {
      return props.ticket.sections?.edrMetaData?.showExpandedFindings;
    });

    const filesToLoad = computed(() => {
      return foldersTotal.value - (folders.value ?? []).length;
    });

    const shouldHaveExpander = (detailRecord: AnomalyDetection) => {
      const { orgName, threatDetectionDate, proxyType } = detailRecord;

      return orgName || threatDetectionDate || proxyType;
    };

    const getThreatType = (threatTypes: string[]) => threatTypes.join(", ");

    const getItems = async (virtualScroll = false) => {
      await ticketStore.getMassDeleteDownloadFolders({
        eventId: props.ticket.eventId,
        page: page.value,
        virtualScroll,
      });
    };

    const loadMoreItems = () => {
      page.value += 1;
      getItems(true);
    };

    watch(expanded, async (currentValue, prevValue) => {
      const expandedItemId = difference(currentValue, prevValue)[0];
      if (expandedItemId) {
        const item = folders.value.find((v) => v.id === expandedItemId)!;
        const files = await ticketStore.getMassDeleteDownloadFiles({
          eventId: props.ticket.eventId,
          page: 0,
          folderId: item.id,
        });
        fileValuesMap[item.path] = files.items;
      }
    });

    const getFiles = (item: AbnormalDataManipulationFolder) => fileValuesMap[item.path] ?? [];

    const viewAllFiles = ({ id, path }: AbnormalDataManipulationFolder) => {
      router.push({
        name: RouteName.ABNORMAL_DATA_MANIPULATION_DETAILS_PAGE,
        query: {
          ticketId: props.ticket.eventId,
          path,
          pathId: id,
          eventTrigger: props.ticket.eventTrigger,
        },
      });
    };

    onMounted(async () => {
      await getItems();
    });

    return {
      tableHeaders: [
        { title: "", key: "data-table-expand", sortable: false },
        {
          key: "name",
          sortable: false,
        },
        { key: "execution-time", sortable: false },
      ],
      page,
      expanded,
      getThreatLevelColor,
      expandedFullDetails,
      folders,
      foldersLoaded,
      foldersTotal,
      mitreData,
      singleExpand,
      showExpander,
      getThreatType,
      locations,
      showFullDetails,
      getFormattedDateTime,
      filesToLoad,
      loadMoreItems,
      shouldHaveExpander,
      getFiles,
      viewAllFiles,
    };
  },
});
</script>

<style lang="scss" scoped>
.load-more-section {
  border-left: 1px solid rgb(var(--v-theme-indigo-pale));
  border-right: 1px solid rgb(var(--v-theme-indigo-pale));
  border-bottom: 1px solid rgb(var(--v-theme-indigo-pale));
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(*) {
  .v-data-table__expanded__content {
    box-shadow: unset !important;
  }

  .expandable-content {
    .v-table__wrapper {
      border: 1px solid rgb(var(--v-theme-indigo-faint));
    }
  }

  .hostname-column {
    max-width: 340px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .v-btn--fab.v-size--small {
    height: 30px;
    width: 30px;
  }

  .v-btn {
    padding: 0 !important;
  }

  .findings-wrapper {
    padding: 0 !important;
  }
}

.full-details-table-row {
  height: 76px;
}

.countries-table {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
  border-radius: 0 !important;
}

.folder-table {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
  border-radius: 0 !important;
}

.country-column {
  min-width: 110px;
}
</style>
