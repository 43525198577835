<template>
  <v-select
    v-model="modelValue"
    :items="items"
    :menu-props="{ maxHeight: '300' }"
    class="msp-workspace-filter"
    :class="{
      'filter-active': modelValue && modelValue.length > 0,
    }"
    :disabled="disabled"
    :placeholder="$t('general.customer')"
    data-testid="msp-workspace-select"
    density="compact"
    outlined
    multiple
    rounded
    hide-details
    background-color="white"
    item-value="workspaceId"
  >
    <template #selection="{ index }">
      <span v-if="index === 0" class="body2">
        {{ $t("general.customer") }}
      </span>
    </template>
    <template #label>
      <span v-if="modelValue && modelValue.length > 0" class="filter-label">
        {{ modelValue.length }}
      </span>
    </template>
    <template #prepend-item>
      <div class="bg-white px-4 py-2 position-sticky search-text-field__container z-index-9999">
        <v-text-field
          density="comfortable"
          rounded
          class="search-text-field"
          min-width="400"
          clear-icon="$x"
          :placeholder="$t('general.search')"
          prepend-inner-icon="$search"
          clearable
          variant="filled"
          bg-color="indigo-faint"
          :hide-details="true"
          @update:model-value="searchTerm = $event"
        >
        </v-text-field>
      </div>
    </template>
    <template #item="{ item, props }">
      <v-list-item v-bind="props" :title="item.raw.workspaceName">
        <template v-slot:prepend="{ isActive }">
          <v-list-item-action start>
            <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
          </v-list-item-action>
        </template>
      </v-list-item>
    </template>
  </v-select>
</template>

<script lang="ts">
import { defineComponent, onMounted, type PropType, ref, watch, computed } from "vue";
import { type MspHierarchyItem, useMspStore } from "@/_store/msp.module.ts";

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<string[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["value-updated"],
  setup(props, { emit }) {
    const allItems = ref<MspHierarchyItem[]>([]);
    const modelValue = ref<string[]>(props.value);
    const searchTerm = ref("");
    const mspStore = useMspStore();
    const getMspDescendants = async () => {
      try {
        const { data } = await mspStore.getMspWorkspaceDescendants();
        allItems.value = data;
      } catch (e) {
        console.error(e);
      }
    };

    const items = computed(() => {
      if (searchTerm.value) {
        return allItems.value.filter((item: MspHierarchyItem) =>
          item.workspaceName.toLowerCase().includes(searchTerm.value.toLowerCase())
        );
      }
      return allItems.value;
    });

    onMounted(async () => {
      await getMspDescendants();
    });

    watch(modelValue, (newVal) => {
      emit("value-updated", newVal);
    });

    watch(
      () => props.value,
      (newVal) => {
        modelValue.value = newVal;
      }
    );

    return {
      searchTerm,
      items,
      modelValue,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(.search-text-field) {
  .v-field__outline {
    display: none !important;
  }
}

.search-text-field__container {
  top: -8px;
}
</style>
