import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { TicketTrigger } from "@/constants/tickets";
import { convertTimeFrameToUnix } from "@/_helpers/utils";
import type { TimeFrame } from "@/types";
import type { SubscriptionModule } from "@/constants/workplaces";
import moment from "moment/moment";

export interface ExecutiveSummaryReportData {
  users: {
    total: number;
    protected: number;
    unProtected: number;
  };
  devices: {
    total: number;
    types: { [displayValue: string]: number };
  };
  tickets: {
    total: number;
    processed: number;
    unProcessed: number;
  };
  topVulnerabilities: {
    [key in TicketTrigger]: number;
  };
  protection: ExecutiveSummaryReportProtectionSettings;
}

export interface DnsSummaryReportData {
  totalQueries: number;
  totalBlockedQueries: number;
  generalChartData: Array<DnsReportChartData>;
  blockedChartData: Array<DnsReportChartData>;
  topDomainsPermitted: Array<DomainData>;
  topDomainsBlocked: Array<DomainData>;
  topClients: Array<HostData>;
  topClientsBlocked: Array<HostData>;
}

export interface DnsReportChartData {
  x: string;
  y: number;
  startDate: number;
  endDate: number;
}

interface DomainData {
  domain: string;
  count: number;
}
interface HostData {
  host: string;
  count: number;
}

export enum ReportType {
  EXECUTIVE_SUMMARY = "workspace",
  MANAGED_SERVICES_SUMMARY = "soc",
  DNS_SUMMARY = "dns",
  SECURED_MESSAGES = "securedMessages",
}

export type ExecutiveSummarySecurityAwarenessReportData = {
  emailsSent: number;
  trainingsSent: number;
  trainingsCompleted: Record<string, number>;
  topUsersFailedPhishing: Record<string, number>;
  topUsersFailedTraining: Record<string, number>;
};

export type ExecutiveSummaryReportProtectionSettings = {
  [moduleName in Exclude<SubscriptionModule, SubscriptionModule.SECURITY_AWARENESS>]: {
    vulnerabilities: {
      [key in TicketTrigger]: number;
    };
    violators: { [displayValue: string]: number };
  };
} & {
  [SubscriptionModule.SECURITY_AWARENESS]: ExecutiveSummarySecurityAwarenessReportData;
};

export type ManagedServicesResolvedTickets = {
  [moduleName in SubscriptionModule]: {
    total: number;
    vulnerabilities: {
      [key in TicketTrigger]: number;
    };
  };
};

export interface ManagedServicesReportData {
  totalResolvedTickets: number;
  resolvedTicketsByModules: ManagedServicesResolvedTickets;
}

export interface DnsReportReload {
  fromTime: number;
  toTime: number;
  refresh: boolean;
}

export interface SecuredMessagesReportData {
  total: number;
  protectedUsers: {
    [email: string]: number;
  };
}

interface ReportsStoreState {
  executiveSummaryReport?: ExecutiveSummaryReportData;
  dnsSummaryReport?: DnsSummaryReportData;
  selectedTimeFrame?: TimeFrame;
  managedServicesSummaryReport?: ManagedServicesReportData;
  securedMessagesSummaryReport?: SecuredMessagesReportData;
  loading: boolean;
  reportVersion: string;
}

const defaultReportsState: ReportsStoreState = {
  executiveSummaryReport: undefined,
  managedServicesSummaryReport: undefined,
  dnsSummaryReport: undefined,
  selectedTimeFrame: undefined,
  securedMessagesSummaryReport: undefined,
  loading: false,
  reportVersion: "",
};

export const useReportsStore = defineStore("reports", {
  state: (): ReportsStoreState => ({ ...defaultReportsState }),
  actions: {
    async getExecutiveSummaryReport() {
      const selectedTimeFrame = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getExecutiveReport(selectedTimeFrame),
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);

        this.executiveSummaryReport = data;

        this.reportVersion = getReportVersion();
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async getDnsReport(refresh = true) {
      const selectedTimeFrame = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getDnsReport({ ...selectedTimeFrame, refresh }),
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);
        this.dnsSummaryReport = data;
        this.reportVersion = getReportVersion();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getManagedServicesSummaryReport() {
      const selectedTimeFrame = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getManagedServicesReport(selectedTimeFrame),
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);
        this.managedServicesSummaryReport = data;
        this.reportVersion = getReportVersion();
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async getSecuredMessagesSummaryReport() {
      const selectedTimeFrame = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getSecuredMessagesReport(selectedTimeFrame),
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);
        this.securedMessagesSummaryReport = data;
        this.reportVersion = getReportVersion();
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async uploadPdfReport(filename: string, pdfBlob: Blob, reportType: ReportType) {
      const data = new FormData();
      data.append("file", pdfBlob, filename);
      const request = {
        ...api.uploadPdfReport,
        data,
        params: {
          reportType,
        },
      };

      try {
        await axiosInstance.request(request);
      } catch (e) {
        console.error(e);
      }
    },
  },
});

export function getReportVersion(): string {
  return moment().format("YYYYMMDD_HHmmss");
}
