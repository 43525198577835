import { i18n } from "@/plugins/i18n.ts";

import { TicketAction, TicketTrigger, TicketType } from "@/constants/tickets.ts";
import { axiosInstance } from "@/plugins/https.ts";
import { defineStore } from "pinia";
import { type MspTicketsFilters, useFiltersStore } from "@/_store/filters.module.ts";
import type { Paged, Pagination } from "@/types.ts";
import api from "@/_helpers/api.ts";
import { useSnackbarStore } from "@/_store";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module.ts";
import { downloadFile, handleVirtualScrollData, isAccessRestricted } from "@/_helpers/utils.ts";
import type { SubscriptionModule } from "@/constants/workplaces.ts";
import type { DialogDataConfig } from "@/_store/dialogs.module.ts";
import type { Ticket, TicketDetails, TicketSection } from "@/_store/tickets/tickets.module.ts";
import { StoreModule } from "@/constants/store.ts";
import { addEncryptDriveMetadata } from "@/_store/tickets/adapters.ts";
import {
  convertMspTicketFiltersForBackend,
  handleTicketActionSnackbar,
} from "@/_store/msp/tickets/adapters.ts";
import type {
  AbnormalDataManipulationFile,
  AbnormalDataManipulationFolder,
  EdrDetectionAffectedDevice,
  FileFinding,
} from "@/_store/tickets/ticket.module.ts";
import type { ExportDialogPayload } from "@/_store/exports.module.ts";
import { ExportChoice } from "@/_store/exports.module.ts";
import cloneDeep from "lodash/cloneDeep";

export type MspTicket = Omit<Ticket, "socStatus"> & {
  workspaceId: string;
  workspaceName: string;
};

export type MspTicketDetails = Omit<TicketDetails, "socStatus"> & {
  workspaceId: string;
  workspaceName: string;
};

export interface MspTicketsStoreState {
  allTickets: number;
  pages: number;
  pagination: Pagination;
  tickets: MspTicket[];
  sorting: {
    sortBy: string;
    sortDesc: boolean;
  };
  showSkeletonLoader: boolean;
  loading: boolean;
  ticketDetailsLoading: boolean;
  activityLogsLoading: boolean;
  ticketDetails: MspTicketDetails;
  selectedEventId: string;
  fullDetails: {
    fileFindings: FileFinding[];
    fileFindingsLoading: boolean;
    fileFindingsTotal: number;
    folders: AbnormalDataManipulationFolder[];
    foldersLoaded: boolean;
    foldersTotal: number;
    edrAffectedDevices: EdrDetectionAffectedDevice[];
    edrAffectedDevicesLoading: boolean;
    edrAffectedDevicesTotal: number;
  };
}

const defaultTicketsState = {
  allTickets: 0,
  pages: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  tickets: [],
  sorting: {
    sortBy: "lastSeen",
    sortDesc: true,
  },
  showSkeletonLoader: true,
  loading: true,
  ticketDetailsLoading: false,
  activityLogsLoading: false,
  selectedEventId: "",
  ticketDetails: {
    activityLogs: [],
    activityLogsTotal: 0,
    processed: false,
    eventId: "",
    actions: [],
    potentiallyProtectableUsers: [],
    startTime: 0,
    endTime: 0,
    creationTime: 0,
    sections: {} as TicketSection,
    noProtectedUsersAtCreation: false,
    socModuleEnabled: false,
    sensitiveDataRestricted: false,
    lastLoggedInUsers: [],
    eventType: "" as TicketType,
    eventTrigger: "" as TicketTrigger,
    moduleName: "" as SubscriptionModule,
    workspaceId: "",
    workspaceName: "",
  } as MspTicketDetails,
  fullDetails: {
    fileFindings: [] as FileFinding[],
    fileFindingsLoading: false,
    fileFindingsTotal: 0,
    folders: [],
    foldersLoaded: false,
    foldersTotal: 0,
    edrAffectedDevices: [],
    edrAffectedDevicesLoading: false,
    edrAffectedDevicesTotal: 0,
  },
};

export const useMspTicketsStore = defineStore(StoreModule.MSP_TICKETS, {
  state: (): MspTicketsStoreState => cloneDeep({ ...defaultTicketsState }),
  actions: {
    resetState() {
      this.$reset();
    },
    setPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
    resetPagination() {
      this.pagination = {
        page: 0,
        pageSize: 25,
      };
    },
    async getMspTickets(showSkeletonLoader: boolean = true) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;
      const filtersStore = useFiltersStore();

      const ticketsFilters = {
        ...convertMspTicketFiltersForBackend({ ...filtersStore.filters.mspTickets }),
      };
      const request = {
        ...api.getMspTickets(),
        params: {
          ...ticketsFilters,
          ...this.pagination,
          sort: "lastEventTime,desc",
        },
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.tickets = data.items;
        this.allTickets = data.total;
      } catch (err) {
        console.error(err);
      } finally {
        this.showSkeletonLoader = false;
        this.loading = false;
      }
    },
    async applyPaginationChange(payload: Pagination) {
      this.setPagination(payload);
      await this.getMspTickets();
    },
    async getMspTicketDetails({ eventId, workspaceId }: { eventId: string; workspaceId: string }) {
      this.ticketDetailsLoading = true;
      this.selectedEventId = eventId;

      const eventInfoRequest = {
        ...api.mspTicket(eventId),
        params: {
          workspaceId,
        },
      };

      const activityLogsRequest = {
        ...api.getMspTicketActivityLogs(eventId),
        params: {
          page: 0,
          pageSize: 5,
          workspaceId,
        },
      };

      const activityLogsAccessRestricted = isAccessRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ACTIVITY_LOGS
      );

      try {
        const [eventInfoResponse, eventActivityLogsResponse] = await Promise.all([
          axiosInstance.request(eventInfoRequest),
          activityLogsAccessRestricted
            ? Promise.resolve({ data: { items: [], total: 0 } })
            : axiosInstance.request(activityLogsRequest),
        ]);

        const activityLogs = eventActivityLogsResponse?.data.items ?? [];
        const activityLogsTotal = eventActivityLogsResponse?.data.total ?? 0;

        this.ticketDetails = {
          ...eventInfoResponse.data,
          activityLogs,
          activityLogsTotal,
        };
      } catch (error) {
        console.error(error);
      } finally {
        this.ticketDetailsLoading = false;
      }
    },
    async exportTicketsToCsv({ type, filters }: ExportDialogPayload<MspTicketsFilters>) {
      const { eventTriggers, widget, childWorkspaceIds } = filters;
      const { fromTime, toTime } = convertMspTicketFiltersForBackend(filters);

      const filtersPayload =
        type === ExportChoice.ALL
          ? { directDescendantsOnly: false }
          : {
              fromTime,
              toTime,
              processed: null,
              eventTriggers,
              widget,
              childWorkspaceIds: childWorkspaceIds ?? [],
            };

      const request = {
        ...api.exportMspTicketsToCsv(),
        data: {
          filters: filtersPayload,
        },
      };
      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.exportCsvStarted")
        );
      } catch (err) {
        console.error(err);
      }
    },
    async applyMspTicketAction(
      payload: DialogDataConfig<
        MspTicketDetails & { name?: string; closeTicket?: boolean },
        TicketAction
      >
    ) {
      try {
        const request = {
          ...api.mspTicketAction(payload.item.eventId),
          data: {
            action: payload.action,
            service: payload.item?.sections?.serviceName ?? "",
            closeTicket: payload.item.closeTicket,
          },
          params: {
            workspaceId: payload.item.workspaceId,
          },
        };

        if (payload.action === TicketAction.ENCRYPT_DRIVE) {
          addEncryptDriveMetadata(request, payload);
        }

        await axiosInstance.request(request);
        await this.getMspTickets();
        handleTicketActionSnackbar(payload);
      } catch (err) {
        console.error(err);
      }
    },
    async applyMspTicketDownloadEmailAction(payload: { item: Ticket; action: TicketAction }) {
      const request = {
        ...api.mspTicketDownloadAction(payload.item.eventId),
        data: {
          action: payload.action,
        },
        params: {
          workspaceId: payload.item.workspaceId,
        },
      };

      try {
        const { data, headers } = await axiosInstance.request(request);
        const blob = new Blob([data], { type: headers["content-type"] });
        const fileName = headers["x-coro-attachment-filename"];
        downloadFile(blob, fileName);

        await this.getMspTickets();

        const snackbarStore = useSnackbarStore();

        snackbarStore.addGenericSuccess(
          i18n.global.t(`snackbar.messages.event.${payload.action}`, {
            fileName,
          })
        );
      } catch (err) {
        console.error(err);
      }
    },
    async getMspTicketActivityLogs({
      page,
      eventId,
      workspaceId,
    }: {
      page: number;
      eventId: string;
      workspaceId?: string;
    }) {
      this.activityLogsLoading = true;

      try {
        const request = {
          ...api.getMspTicketActivityLogs(eventId),
          params: {
            page,
            pageSize: 5,
            workspaceId,
          },
        };

        const { data } = await axiosInstance.request(request);

        this.ticketDetails = {
          ...this.ticketDetails,
          activityLogs: handleVirtualScrollData(this.ticketDetails.activityLogs, data.items, "id"),
        };

        this.activityLogsLoading = false;
      } catch (error) {
        console.error(error);
      }

      this.activityLogsLoading = false;
    },
    async undoActivityLog(id: string) {
      this.activityLogsLoading = true;

      try {
        const request = {
          ...api.activityLogsUndo(id),
          params: {
            workspaceId: this.ticketDetails.workspaceId,
          },
          headers: {
            Workspace: this.ticketDetails.workspaceId,
          },
        };

        await axiosInstance.request(request);

        await this.updateMspTicketActivityLogs();
        await this.getMspTickets();
      } catch (error) {
        console.error(error);
      }

      this.activityLogsLoading = false;
    },
    async updateMspTicketActivityLogs() {
      this.activityLogsLoading = true;
      try {
        const request = {
          ...api.getMspTicketActivityLogs(this.ticketDetails.eventId),
          params: {
            page: 0,
            pageSize: 25,
            workspaceId: this.ticketDetails.workspaceId,
          },
        };
        const { data } = await axiosInstance.request(request);
        this.ticketDetails.activityLogs = data.items;
      } catch (e) {
        console.error(e);
      }

      this.activityLogsLoading = false;
    },
    async getFileFindings({
      eventId,
      page = 0,
      virtualScroll,
    }: {
      page: number;
      virtualScroll?: boolean;
      eventId: string;
    }) {
      this.fullDetails.fileFindingsLoading = true;
      try {
        const request = {
          ...api.mspTicketFindings(eventId),
          params: {
            page,
            pageSize: 10,
            workspaceId: this.ticketDetails.workspaceId,
          },
        };
        const { data } = await axiosInstance.request<Paged<FileFinding>>(request);
        for (const item of data.items) {
          item.id = item.filePath + item.fileName;
        }
        this.fullDetails.fileFindings = virtualScroll
          ? handleVirtualScrollData(this.fullDetails.fileFindings, data.items, "id")
          : data.items;
        this.fullDetails.fileFindingsTotal = data.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.fullDetails.fileFindingsLoading = false;
      }
    },
    async getMassDeleteDownloadFolders({
      eventId,
      page = 0,
      virtualScroll,
    }: {
      eventId: string;
      page: number;
      virtualScroll?: boolean;
    }) {
      try {
        const request = {
          ...api.mspTicketAbnormalDataManipulationFolders(eventId),
          params: {
            page,
            pageSize: 10,
            workspaceId: this.ticketDetails.workspaceId,
          },
        };
        const { data } = await axiosInstance.request(request);
        this.fullDetails.folders = virtualScroll
          ? handleVirtualScrollData(this.fullDetails.folders, data.items, "id")
          : data.items;
        this.fullDetails.foldersTotal = data.total;
      } catch (error) {
        console.error(error);
      }
      this.fullDetails.foldersLoaded = true;
    },
    async getMassDeleteDownloadFiles({
      eventId,
      page = 0,
      folderId,
    }: {
      eventId: string;
      page: number;
      folderId: string;
    }): Promise<Paged<AbnormalDataManipulationFile>> {
      try {
        const request = {
          ...api.mspTicketAbnormalDataManipulationFiles({
            eventId,
            folderId,
          }),
          params: {
            page,
            pageSize: 10,
            workspaceId: this.ticketDetails.workspaceId,
          },
        };
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (error) {
        console.error(error);
        return { items: [], total: 0 };
      }
    },
    async getEdrAffectedDevices({
      eventId,
      page = 0,
      virtualScroll,
    }: {
      eventId: string;
      page: number;
      virtualScroll?: boolean;
    }) {
      this.fullDetails.edrAffectedDevicesLoading = true;

      try {
        const request = {
          ...api.mspTicketEdrAffectedDevices(eventId),
          params: { page, pageSize: 10, workspaceId: this.ticketDetails.workspaceId },
        };

        const { data } = await axiosInstance.request(request);
        this.fullDetails.edrAffectedDevices = virtualScroll
          ? handleVirtualScrollData(
              this.fullDetails.edrAffectedDevices,
              data.items,
              "enrollmentCode"
            )
          : data.items;
        this.fullDetails.edrAffectedDevicesTotal = data.total;
      } catch (error) {
        console.error(error);
      }

      this.fullDetails.edrAffectedDevicesLoading = false;
    },
    async getTicketRecipients(eventId: string, isDeviceEvent: boolean, workspaceId: string) {
      const params: { protectedOnly?: boolean; workspaceId: string } = { workspaceId };
      if (isDeviceEvent) {
        params.protectedOnly = false;
      }
      const request = {
        ...api.getMspTicketRecipients(eventId),
        params,
      };
      try {
        const { data } = await axiosInstance.request(request);

        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async onContactUser(
      payload: DialogDataConfig<{
        includeEventDetails: boolean;
        contactMessage: string;
        recipients: string[];
        id: string;
        by: "event";
        workspaceName: string;
      }>
    ) {
      const { includeEventDetails, contactMessage, recipients, by, id, workspaceName } =
        payload.item;
      const request = {
        ...api.contactUser,
        params: {
          id: id,
          by: by,
          workspaceId: this.ticketDetails.workspaceId,
        },
        data: {
          contactMessage: contactMessage,
          includeEventDetails: includeEventDetails,
          recipients: recipients,
          workspaceDisplayName: workspaceName,
        },
        headers: {
          Workspace: this.ticketDetails.workspaceId,
        },
      };

      try {
        await axiosInstance.request(request);

        useSnackbarStore().addGenericSuccess(i18n.global.t("snackbar.messages.users.contactUser"));

        if (by === "event") {
          await this.updateMspTicketActivityLogs();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
