<template>
  <div>
    <div class="subtitle1">{{ $t("ticketDetails.users") }}</div>
    <div class="body1 mb-4">
      <div data-testid="ticket-preview-users">
        <span class="body1 mr-1" data-testid="ticket-preview-user">{{ users[0] }}</span>
        <span
          v-if="users.length > 1"
          role="button"
          data-testid="ticket-preview-plus-n-btn"
          class="plus-one-text"
          @click="openListModal(TicketsModal.USERS, users)"
          >&nbsp;+{{ users.length - 1 }}</span
        >
      </div>
      <div v-if="showAddToProtectionBtn">
        <div
          class="body2 text-red-dark mb-3"
          data-testid="ticket-preview-potentially-protectable-users"
        >
          {{
            $t(
              "ticketDetails.notProtectedUsersNotice",
              { n: ticketDetails.potentiallyProtectableUsers.length },
              ticketDetails.potentiallyProtectableUsers.length
            )
          }}
        </div>
        <v-btn
          data-testid="tickets-page-add-users-btn"
          variant="outlined"
          rounded
          :disabled="addUsersNotAllowed"
          @click="addUsers(usersToAdd)"
        >
          {{ $t("ticketDetails.addProtectionBtn") }}
        </v-btn>
      </div>
    </div>

    <div class="subtitle1">{{ $t("ticketDetails.when") }}</div>
    <div class="body1 mb-4" data-testid="ticket-preview-when">
      {{ getFormattedDateTime(ticketDetails.endTime) }}
    </div>

    <div class="subtitle1">{{ $t("general.service") }}</div>
    <div class="d-flex align-center mb-4">
      <v-icon
        v-if="ticketDetails.sections.serviceName === Service.PROXY"
        size="40"
        class="mr-2"
        icon="$accessRestrictions"
      >
      </v-icon>
      <coro-icon v-else class="service-icon mr-2" :icon-name="ticketDetails.sections.serviceName" />
      <div
        class="subtitle1"
        :data-testid="`ticket-preview-service-${ticketDetails.sections.serviceName}`"
      >
        {{ serviceNameTitle }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from "vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { useTicketPreviewGeneralInfo } from "@/composables/useTicketPreviewGeneralInfo";
import intersection from "lodash/intersection";
import { arrayToLowerCase } from "@/_helpers/utils";
import { useI18n } from "vue-i18n";
import { Service } from "@/constants/cloud-apps";
import { TicketsModal, ViolationDirection } from "@/constants/tickets";
import { ProxyDirection } from "@/constants/email-proxy";
import CoroIcon from "@/components/CoroIcon.vue";
import union from "lodash/union";

export default defineComponent({
  components: { CoroIcon },
  props: {
    ticketDetails: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();

    const { getFormattedDateTime, addUsersNotAllowed, usersToAdd, addUsers, openListModal } =
      useTicketPreviewGeneralInfo(props.ticketDetails);

    const users = computed<string[]>(() => {
      const violationDirection =
        props.ticketDetails?.sections?.userDataAccessViolation?.violationDirection;
      if (violationDirection === ViolationDirection.ACCESS) {
        return union(
          props.ticketDetails.sections.userDataAccessViolation.users,
          props.ticketDetails.potentiallyProtectableUsers
        );
      } else if (violationDirection === ViolationDirection.SHARE) {
        return props.ticketDetails.sections.userDataAccessViolation.users;
      }
      return [props.ticketDetails.sections.triggeredUser];
    });

    const showAddToProtectionBtn = computed(() => {
      // if processed and not white ticket, then the action button will be visible (`Re-open ticket`)
      const showActionBtn = props.ticketDetails.actions?.length;
      const isUserUnprotected = intersection(
        arrayToLowerCase(users.value),
        arrayToLowerCase(props.ticketDetails.potentiallyProtectableUsers)
      ).length;
      return showActionBtn && isUserUnprotected;
    });

    const serviceNameTitle = computed(() => {
      const serviceName = i18n.t(`services.${props.ticketDetails.sections.serviceName}`);
      if (props.ticketDetails.sections.serviceName === Service.PROXY) {
        const direction =
          props.ticketDetails.sections.emailMetaData?.emailProxyDirection === ProxyDirection.INBOUND
            ? i18n.t("emailProxy.directions.inbound")
            : i18n.t("emailProxy.directions.outbound");

        return `${direction} ${serviceName}`;
      }
      return serviceName;
    });

    return {
      getFormattedDateTime,
      addUsersNotAllowed,
      usersToAdd,
      addUsers,
      showAddToProtectionBtn,
      serviceNameTitle,
      openListModal,
      TicketsModal,
      Service,
      users,
    };
  },
});
</script>

<style lang="scss" scoped>
.service-icon {
  width: 40px;
  height: 40px;
}
</style>
