<template>
  <filter-wrapper :show-clear-button="false" class="mt-2 filter-wrapper">
    <!--    Devices filters section-->
    <div v-if="isModelForContext(model, FilterContext.MSP_DEVICES)" class="d-flex flex-column">
      <msp-workspace-descentant-select
        class="mr-2 filter"
        :value="model.childWorkspaceIds"
        :disabled="disableFilters"
        @value-updated="model.childWorkspaceIds = $event"
      />
      <v-select
        v-model="model.labels"
        :items="labelItems"
        :disabled="disableFilters"
        bg-color="white"
        menu-icon="icon-triangle"
        item-value="id"
        item-title="name"
        :menu-props="{ maxHeight: '300' }"
        class="filter filter-menu mt-2"
        :class="{ 'filter-active': model.labels?.length }"
        :placeholder="$t('devices.filters.groups')"
        :no-data-text="$t('devices.filters.label.noData')"
        density="compact"
        variant="outlined"
        multiple
        rounded
        hide-details
        background-color="white"
        return-object
      >
        <template #selection="{ index }">
          <span v-if="index === 0" class="body2">{{ $t("devices.filters.groups") }}</span>
        </template>
        <template #label>
          <span v-if="model.labels?.length" class="filter-label">
            {{ model.labels.length }}
          </span>
        </template>
        <template #item="{ item, props }">
          <v-list-subheader v-if="'subheader' in item.raw" :title="item.raw.name" />
          <v-divider v-else-if="'divider' in item.raw" v-bind="props" />
          <v-list-item v-else v-bind="props" title="">
            <template v-slot:prepend="{ isActive }">
              <v-list-item-action start>
                <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
              </v-list-item-action>
            </template>

            <v-list-item-title class="d-flex align-center label-popup-block" title="">
              <span class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
              <span>{{ item.raw.name }}</span>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
      <v-select
        v-model="model.osVersion"
        :items="osVersions"
        :menu-props="{ maxHeight: '300' }"
        :disabled="disableFilters"
        class="os-version-filter filter-menu mt-2 fit"
        :class="{ 'filter-active': model.osVersion }"
        :placeholder="$t('devices.filters.osVersion')"
        data-testid="devices-page-os-version-filter"
        density="compact"
        item-value="name"
        item-title="name"
        rounded
        hide-details
        background-color="white"
        clearable
        clear-icon="$x"
      >
        <template #selection="{ item }">
          <span class="body2">
            {{ item.title }}
          </span>
        </template>
        <template #item="{ item, props }">
          <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
          <v-list-item
            v-else
            :data-testid="`devices-page-os-version-filter-${item}-item`"
            v-bind="props"
          >
          </v-list-item>
        </template>
      </v-select>
      <v-select
        v-model="model.clientVersion"
        :items="clientVersions"
        :disabled="disableFilters"
        :menu-props="{ maxHeight: '300' }"
        class="filter filter-menu mt-2 fit"
        :class="{ 'filter-active': model.clientVersion }"
        :placeholder="$t('devices.filters.clientVersion')"
        data-testid="devices-page-client-version-filter"
        density="compact"
        variant="outlined"
        item-value="name"
        item-title="name"
        rounded
        hide-details
        background-color="white"
        clearable
        clear-icon="$x"
      >
        <template #selection="{ item }">
          <span class="body2">
            {{ `Coro ${item.title}` }}
          </span>
        </template>
        <template #item="{ item, props }">
          <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
          <v-list-item
            v-else
            v-bind="props"
            :data-testid="`devices-page-client-version-filter-${item}-item`"
          >
          </v-list-item>
        </template>
      </v-select>
      <v-select
        v-model="model.vulnerabilities"
        :items="Object.values(DeviceVulnerability)"
        :disabled="disableFilters"
        :menu-props="{ maxHeight: '300' }"
        class="filter filter-menu mt-2"
        :class="{
          'filter-active': model.vulnerabilities && model.vulnerabilities.length,
        }"
        :placeholder="$t('devices.filters.vulnerability')"
        density="compact"
        variant="outlined"
        multiple
        rounded
        hide-details
        background-color="white"
      >
        <template #selection="{ index }">
          <span v-if="index === 0" class="body2">{{ $t("devices.filters.vulnerability") }}</span>
        </template>
        <template #label>
          <span v-if="model.vulnerabilities?.length" class="filter-label">{{
            model.vulnerabilities.length
          }}</span>
        </template>
        <template #item="{ item, props }">
          <v-list-item v-bind="props" :title="$t(`tickets.eventTriggers.${camelCase(item.raw)}`)">
            <template v-slot:prepend="{ isActive }">
              <v-list-item-action start>
                <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-select>
    </div>

    <!--    Users filters section-->
    <div v-else-if="isModelForContext(model, FilterContext.MSP_USERS)" class="d-flex flex-column">
      <msp-workspace-descentant-select
        class="mr-2 filter"
        :value="model.workspaceIds as string[]"
        :disabled="disableFilters"
        @value-updated="model.workspaceIds = $event"
      />
      <v-select
        v-model="model.cloudServices"
        :items="Object.values(Service)"
        :disabled="disableFilters"
        class="filter-menu cloud-services-filter mr-2"
        :class="{ 'filter-active': !!model?.cloudServices?.length }"
        :placeholder="$t('general.cloudApps')"
        density="compact"
        variant="outlined"
        multiple
        rounded
        hide-details
        background-color="white"
        return-object
      >
        <template #selection="{ index }">
          <span v-if="index === 0" class="body2">{{ $t("general.cloudApps") }}</span>
        </template>
        <template #label>
          <span v-if="model.cloudServices?.length" class="filter-label">
            {{ model.cloudServices?.length }}
          </span>
        </template>
        <template #item="{ item, props }">
          <v-list-item v-bind="props" :title="$t(`services.${item.raw}`)">
            <template v-slot:prepend="{ isActive }">
              <v-list-item-action start>
                <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-select>
    </div>

    <!--    Tickets Filters section-->
    <div v-else-if="isModelForContext(model, FilterContext.MSP_TICKETS)" class="d-flex flex-column">
      <msp-workspace-descentant-select
        class="mr-2 filter"
        :value="model.childWorkspaceIds as string[]"
        :disabled="disableFilters"
        @value-updated="model.childWorkspaceIds = $event"
      />

      <v-select
        v-model="model.widget"
        :items="widgets"
        :disabled="disableFilters"
        :menu-props="{ maxHeight: '300' }"
        class="filter-menu widget-filter mt-2 fit"
        :class="{ 'filter-active': model.widget }"
        :placeholder="$t('tickets.filters.widgets.placeholder')"
        density="compact"
        item-value="name"
        item-title="name"
        rounded
        hide-details
        background-color="white"
      >
        <template #selection="{ item }">
          <span class="body2">
            {{ $t(`tickets.filters.widgets.${item.title}`) }}
          </span>
        </template>
        <template #item="{ item, props }">
          <v-list-item v-bind="props" :title="$t(`tickets.filters.widgets.${item.title}`)">
          </v-list-item>
        </template>
      </v-select>
      <v-select
        v-model="model.eventTriggers"
        :items="triggers"
        :disabled="disableFilters"
        :menu-props="{ maxHeight: '300' }"
        class="filter-menu triggers-filter mt-2"
        :class="{
          'filter-active': !!model.eventTriggers?.length,
        }"
        :placeholder="$t('tickets.filters.type')"
        density="compact"
        variant="outlined"
        item-value="name"
        item-title="name"
        multiple
        rounded
        hide-details
        background-color="white"
      >
        <template #selection="{ index }">
          <span v-if="index === 0" class="body2">{{ $t("tickets.filters.type") }}</span>
        </template>
        <template #label>
          <span v-if="model.eventTriggers?.length" class="filter-label">{{
            model.eventTriggers.length
          }}</span>
        </template>
        <template #item="{ item, props }">
          <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
          <v-list-item v-else v-bind="props" title="">
            <template v-slot:prepend="{ isActive }">
              <v-list-item-action start>
                <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
              </v-list-item-action>
            </template>

            <v-list-item-title :title="''">
              {{ $t(`tickets.eventTriggers.${item.raw.name}`) }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
      <date-range-picker
        v-model="model.eventTimeRange"
        class="range-picker mt-2"
        :class="{
          'filter-active': model.eventTimeRange.start && model.eventTimeRange.end,
        }"
        :presets="presets"
        :disabled="disableFilters"
        :placeholder="$t('ticketDetails.filters.during')"
        :input-props="{
          'append-icon': 'icon-triangle',
          'hide-details': true,
        }"
        next-icon="icon-expand"
        data-testid="tickets-page-time-range-filter"
        prev-icon="icon-minimize"
        density="compact"
        variant="outlined"
      />
    </div>
  </filter-wrapper>
</template>

<script lang="ts" generic="T">
import { computed, defineComponent, onMounted, type PropType, type Ref, ref, watch } from "vue";
import { ExportCategory } from "@/_store/exports.module";
import {
  defaultFiltersState,
  FilterContext,
  type FiltersState,
  type MspTicketsFilters,
} from "@/_store/filters.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { ActivityLogsType } from "@/constants/activity-logs";
import { eventTriggerFilterMap, TicketsModuleFilter, TicketTrigger } from "@/constants/tickets";
import { type DeviceStatusFilterItem } from "@/_store/devices.module";
import { DeviceVulnerability, OsType } from "@/constants/devices";
import { storeToRefs } from "pinia";
import { camelCase } from "lodash";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash/cloneDeep";
import { useMspDevicesStore } from "@/_store/msp/msp-devices.module.ts";
import MspWorkspaceDescentantSelect from "@/components/msp/MspWorkspaceDescentantSelect.vue";
import { Service } from "@/constants/cloud-apps.ts";
import { getDateRangePresets } from "@/constants/date-range-picker.ts";
import DateRangePicker from "@/components/DateRangePicker.vue";

export default defineComponent({
  components: { DateRangePicker, MspWorkspaceDescentantSelect, FilterWrapper },
  props: {
    filterContext: {
      type: String as PropType<
        FilterContext.MSP_TICKETS | FilterContext.MSP_DEVICES | FilterContext.MSP_USERS
      >,
      required: true,
    },
    disableFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:filters"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const model: Ref<FiltersState[typeof props.filterContext]> = ref(
      cloneDeep(defaultFiltersState[props.filterContext])
    );
    const devicesStore = useMspDevicesStore();
    const osVersions = ref<Array<{ subheader?: true; name: string }>>([]);
    const clientVersions = ref<Array<{ subheader?: true; name: string }>>([]);
    const { osVersions: osVersionsFromStore, clientVersions: clientVersionsFromStore } =
      storeToRefs(devicesStore);
    const presets = computed(() => getDateRangePresets());
    const triggers = computed<{ subheader?: boolean; name: string }[]>(() => {
      if (props.filterContext === FilterContext.MSP_TICKETS) {
        const ticketsFilters = model.value as MspTicketsFilters;
        return ticketsFilters.widget
          ? [
              {
                subheader: true,
                name: i18n.t(`tickets.filters.widgets.${ticketsFilters.widget}`),
              },
              ...sortTriggers(eventTriggerFilterMap[ticketsFilters.widget]).map((v) => ({
                name: v,
              })),
            ]
          : Object.entries(eventTriggerFilterMap).flatMap(([widget, triggers]) => [
              {
                subheader: true,
                name: i18n.t(`tickets.filters.widgets.${widget}`),
              },
              ...sortTriggers(triggers).map((v) => ({ name: v })),
            ]);
      }
      return [];
    });

    const sortTriggers = (triggers: TicketTrigger[]): TicketTrigger[] => {
      // If $te (Translation exists) take event trigger translation, else event type translation
      const getTriggerOrTypeTranslation = (item: string) =>
        i18n.te(`tickets.eventTriggers.${item}`)
          ? i18n.t(`tickets.eventTriggers.${item}`)
          : i18n.t(`tickets.eventTypes.${item}`);
      return triggers.slice().sort((a, b) => {
        return getTriggerOrTypeTranslation(a).localeCompare(getTriggerOrTypeTranslation(b));
      });
    };

    const labelItems = computed(() => {
      const labels = devicesStore.availableLabels ?? [];
      const statuses = devicesStore.statuses ?? [];

      const result = [];
      if (statuses.length) {
        result.push({ subheader: true, name: i18n.t("devices.filters.status") }, ...statuses, {
          divider: true,
        });
      }
      result.push({ subheader: true, name: i18n.t("devices.filters.labels") }, ...labels);
      return result as (
        | { divider: true }
        | { name: string; subheader: true }
        | DeviceStatusFilterItem
      )[];
    });

    const populateVersionsList = () => {
      const osTypesForVersions = [OsType.WINDOWS, OsType.OSX, OsType.IOS, OsType.ANDROID] as const;
      osVersions.value = [];
      clientVersions.value = [];
      osTypesForVersions.forEach((neededVersion) => {
        const lowercaseVersion = neededVersion.toLowerCase() as Lowercase<
          Exclude<OsType, OsType.MAC_OS>
        >;
        if (osVersionsFromStore.value[lowercaseVersion]?.length) {
          osVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...osVersionsFromStore.value[lowercaseVersion]!.map((v) => ({
              name: v,
            }))
          );
        }

        if (clientVersionsFromStore.value?.[lowercaseVersion]?.length) {
          clientVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...clientVersionsFromStore.value[lowercaseVersion]!.slice()
              .sort()
              .reverse()
              .map((v) => ({ name: v }))
          );
        }
      });
    };

    onMounted(async () => {
      await devicesStore.init();
      populateVersionsList();
    });

    watch(
      () => model.value,
      (newVal) => {
        emit("update:filters", newVal);
      },
      { deep: true, immediate: true }
    );

    const isModelForContext = <
      T extends FilterContext.MSP_TICKETS | FilterContext.MSP_DEVICES | FilterContext.MSP_USERS,
    >(
      filterModel: typeof model.value,
      filterContext: T
    ): filterModel is FiltersState[T] => {
      return props.filterContext === filterContext;
    };

    return {
      FilterContext,
      model,
      camelCase,
      activityLogTypes: Object.values(ActivityLogsType),
      widgets: Object.values(TicketsModuleFilter),
      triggers,
      DeviceVulnerability,
      clientVersions,
      osVersions,
      labelItems,
      ExportCategory,
      isModelForContext,
      Service,
      presets,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter,
.filter-menu,
.range-picker {
  width: 240px !important;
}
</style>
