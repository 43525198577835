<template>
  <div class="content-md margin-auto">
    <div class="headline5 mt-4 text-primary">
      {{ $t("mspControlPanel.title") }}
    </div>
    <div class="mt-10 text-primary subtitle1">{{ $t("mspControlPanel.management") }}</div>
    <div
      v-for="managementSetting in managementSettings"
      :key="managementSetting.name"
      class="mr-12"
    >
      <v-btn
        :data-testid="`setting-${kebabCase(managementSetting.name)}-btn`"
        variant="plain"
        class="settings-btn"
        :to="{ name: managementSetting.route }"
      >
        <div class="d-flex justify-center align-center flex-column">
          <div class="mb-2">
            <v-icon size="80" :icon="`$${managementSetting.icon}`" color="primary"> </v-icon>
          </div>
          <div class="body2">{{ managementSetting.name }}</div>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import kebabCase from "lodash/kebabCase";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { RouteName } from "@/constants/routes.ts";
import { CoroIcons } from "@/constants/coro-icon.ts";

export default defineComponent({
  setup: function () {
    const i18n = useI18n();

    const managementSettings = computed(() => {
      return [
        {
          name: i18n.t("settings.accessControl"),
          icon: CoroIcons.ADMIN_USERS,
          route: RouteName.MSP_ACCESS_CONTROL_PAGE,
          hide: false,
        },
      ];
    });

    return {
      kebabCase,
      managementSettings,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .settings-btn {
    height: 150px;
    width: 150px;
    text-transform: capitalize !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    opacity: 1;
  }

  .settings-button__content {
    height: inherit;
    width: inherit;
    font-weight: normal;
    font-size: 16px;
  }

  .settings-btn {
    transition: all 0.4s ease-in-out;
    &--disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .settings-btn:hover {
    transform: scale(1.1);
  }
}
</style>
