<template>
  <div class="v-row h-100 ma-0">
    <div class="v-col-6 py-0 d-flex justify-end">
      <div class="content-block h-100 d-flex justify-center align-center">
        <div class="login-content">
          <img v-if="showBrandedIcon" alt="Logo" :src="account.appLogo" height="36" />
          <v-img
            v-else
            src="/images/logos/coro-logo-large-primary.svg"
            width="110"
            height="36"
            contain
            class="mb-4"
          ></v-img>
          <v-card class="pa-6" color="white">
            <div class="headline5 mb-5">{{ $t("login.title") }}</div>
            <v-btn
              class="mb-4"
              width="170"
              height="44px"
              variant="outlined"
              :disabled="account.requestInProgress"
              rounded
              block
              data-testid="microsoft365-login-btn"
              @click="socialLogin(service.OFFICE_365)"
            >
              <coro-icon :icon-name="service.OFFICE_365" class="cloud-service-icon" />
              {{ $t(`services.${service.OFFICE_365}`) }}
            </v-btn>
            <v-btn
              height="44px"
              width="170"
              variant="outlined"
              :disabled="account.requestInProgress"
              rounded
              block
              data-testid="google-login-btn"
              @click="socialLogin(service.G_SUITE)"
            >
              <coro-icon :icon-name="service.G_SUITE" class="cloud-service-icon" />
              {{ $t(`services.${service.GOOGLE}`) }}
            </v-btn>

            <div v-if="socialLoginError" class="error-block d-flex mb-3 mt-6 align-center w-100">
              <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
              <span class="body2 text-red-dark">
                <template v-if="socialLoginError === accountErrors.GENERAL">
                  {{ $t("login.errors.generalError") }}
                </template>
                <template v-else-if="socialLoginError === accountErrors.NOT_ACCEPTED_INVITATION">
                  {{ $t(`login.errors.${accountErrors.NOT_ACCEPTED_INVITATION}`) }}
                </template>
                <template v-else>
                  {{ $t("login.errors.noAccountFound") }}
                </template>
              </span>
            </div>

            <div class="d-flex align-center mt-6">
              <div class="line"></div>
              <div class="ml-3 mr-3 text-indigo-medium text-uppercase">
                {{ $t("general.or") }}
              </div>
              <div class="line"></div>
            </div>
            <v-form ref="form" v-model="valid">
              <div v-if="account.error" class="error-block d-flex mb-2 mt-2 align-center w-100">
                <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
                <span class="body2 text-red-dark">
                  {{ $t("general.pleaseCorrectErrors") }}
                </span>
              </div>
              <v-text-field
                class="mb-4 mt-6"
                variant="outlined"
                :disabled="account.requestInProgress"
                v-model="localValue.userName"
                :rules="[emailRule()]"
                type="text"
                :placeholder="$t('login.email')"
                validate-on="lazy blur"
                :label="$t('login.email')"
                :hide-details="!!account.error"
                @keyup.enter="loginToConsole()"
              >
              </v-text-field>
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                variant="outlined"
                :disabled="account.requestInProgress"
                v-model="localValue.password"
                :append-inner-icon="showPassword ? 'icon-eye' : 'icon-eye-off'"
                @click:appendInner="showPassword = !showPassword"
                :rules="[required()]"
                :placeholder="$t('login.password')"
                :label="$t('login.password')"
                :hide-details="!!account.error"
                @keyup.enter="loginToConsole()"
              >
              </v-text-field>
              <div class="text-error caption mt-2 mb-2" v-if="!!account.error === true">
                {{ $t(`login.errors.${account.error}`) }}
              </div>
            </v-form>
            <div class="d-flex flex-column justify-center mb-2">
              <v-btn
                color="primary"
                :loading="account.requestInProgress"
                rounded
                block
                size="large"
                @click="loginToConsole()"
              >
                {{ $t("login.signIn") }}
              </v-btn>
              <div class="coro-link cursor-pointer text-center mt-4" @click="forgotPassword()">
                {{ $t("login.forgotPassword") }}
              </div>
            </div>
          </v-card>

          <div v-if="!account.brandingAlias" class="d-flex align-center justify-center mt-3 w-100">
            <span class="caption text-indigo-medium">
              {{ $t("login.dontHaveAccount") }}
            </span>
            <span class="ml-1 coro-link caption" @click="goToSignup()">
              {{ $t("login.signUp") }}
            </span>
          </div>

          <div
            class="caption text-indigo-medium text-center mt-3"
            data-size="invisible"
            data-badge="inline"
            v-html="$t('general.recaptcha')"
          />
        </div>
      </div>
    </div>
    <div class="v-col-6 py-0 d-flex justify-start bg-indigo-instructional">
      <div id="anchor-wrapper" class="content-block">
        <div id="anchor"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import CoroIcon from "@/components/CoroIcon.vue";
import type { VuetifyFormRef } from "@/types";
import { appLogoDefaultPath, useAccountStore } from "@/_store";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { emailRule, required } from "@/_helpers/validators";
import { Service } from "@/constants/cloud-apps";
import { RouteName } from "@/constants/routes";
import { AccountErrors } from "@/constants/account";

export default defineComponent({
  components: {
    CoroIcon,
  },
  setup() {
    const router = useRouter();
    const localValue = ref({
      userName: "",
      password: "",
    });
    const valid = ref(true);
    const showPassword = ref(false);
    const socialLoginError = ref();
    const form = ref<VuetifyFormRef>(null);
    const inviteButtonLoading = ref(false);
    const accountStore = useAccountStore();
    const { login, socialLogin } = accountStore;
    const { account } = storeToRefs(accountStore);

    const showBrandedIcon = computed(() => {
      return account.value.brandingAlias && account.value.appLogo !== appLogoDefaultPath;
    });

    const goToSignup = () => {
      router.push({ name: RouteName.SIGN_UP });
    };

    const forgotPassword = () => {
      router.push({ name: RouteName.FORGOT_PASSWORD });
    };

    const loginToConsole = async () => {
      const validationResult = await form.value?.validate();
      if (validationResult?.valid) {
        await login(localValue.value);
      }
    };

    onMounted(() => {
      accountStore.$reset();
      const urlParams = new URLSearchParams(window.location.search);
      socialLoginError.value = urlParams.get("error");
      router.replace({}).catch(() => {});
    });

    return {
      localValue,
      showPassword,
      valid,
      form,
      emailRule,
      required,
      service: Service,
      inviteButtonLoading,
      forgotPassword,
      loginToConsole,
      socialLogin,
      goToSignup,
      socialLoginError,
      account,
      accountErrors: AccountErrors,
      showBrandedIcon,
    };
  },
});
</script>

<style scoped lang="scss">
.line {
  border-bottom: 1px solid rgb(var(--v-theme-indigo-pale));
  width: 50%;
}

.cloud-service-icon {
  width: 24px;
  height: 24px;
}

.login-content {
  width: 424px;
}

.error-block {
  min-height: 32px !important;
  height: 100%;
}

.content-block {
  width: 600px;
}
</style>
