import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";

export enum RolesAction {
  ADD = "addRole",
  EDIT = "editRole",
  DUPLICATE = "duplicateRole",
  DELETE = "deleteRole",
  UNABLE_TO_DELETE = "unableToDeleteRole",
}

export enum MspRolesAction {
  ADD = "addMspRole",
  EDIT = "editMspRole",
  DUPLICATE = "duplicateMspRole",
  DELETE = "deleteMspRole",
  UNABLE_TO_DELETE = "unableToDeleteMspRole",
}

export enum GlobalRolesAction {
  ADD = "addGlobalRole",
  EDIT = "editGlobalRole",
  DUPLICATE = "duplicateGlobalRole",
  DELETE = "deleteGlobalRole",
  UNABLE_TO_DELETE = "unableToDeleteGlobalRole",
}

export enum RoleType {
  WORKSPACE = "WORKSPACE",
  MSP = "MSP",
  GLOBAL = "GLOBAL",
}

export enum RolePermissionAccessMode {
  VIEW = "view",
  EDIT = "edit",
  NO_ACCESS = "noAccess",
}

export const emptyPermissions = {
  name: null,
  workspaceManagementScope: {
    users: null,
    devices: null,
    cloudApps: null,
    activeSessions: null,
    activityLogs: null,
    connectors: null,
    roles: null,
    reports: null,
    adminUsers: {
      accessMode: null,
      editAccessModePermission: {
        add: true,
        edit: true,
        remove: true,
        assignRoles: false,
        manageContentPermissions: true,
        removeMfaData: true,
      },
    },
  },
  viewsScope: {
    usersView: null,
    devicesView: null,
  },
  protectionScope: {
    [SubscriptionModule.CLOUD_SECURITY]: null,
    [SubscriptionModule.ENDPOINT_SECURITY]: null,
    [SubscriptionModule.EMAIL_SECURITY]: null,
    [SubscriptionModule.USER_DATA_GOVERNANCE]: null,
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: null,
    [SubscriptionModule.EDR]: null,
    [SubscriptionModule.NETWORK]: null,
    managedSoc: null,
    [SubscriptionAddon.INBOUND_GATEWAY]: null,
    [SubscriptionModule.MDM]: null,
    [SubscriptionModule.SECURITY_AWARENESS]: null,
    [SubscriptionAddon.SWG]: null,
    [SubscriptionAddon.WIFI_PHISHING]: null,
    [SubscriptionAddon.SECURED_MESSAGES]: null,
  },
  ticketsScope: {
    [SubscriptionModule.EDR]: {
      accessMode: null,
      editAccessModePermission: {
        lowSeverity: true,
        mediumSeverity: true,
        criticalSeverity: true,
        commentTickets: true,
      },
    },
    [SubscriptionModule.ENDPOINT_SECURITY]: {
      accessMode: null,
      editAccessModePermission: {
        lowSeverity: true,
        mediumSeverity: true,
        criticalSeverity: true,
        commentTickets: true,
      },
    },
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
      accessMode: null,
      editAccessModePermission: {
        lowSeverity: true,
        mediumSeverity: true,
        criticalSeverity: true,
        commentTickets: true,
      },
    },
    [SubscriptionModule.EMAIL_SECURITY]: {
      accessMode: null,
      editAccessModePermission: {
        lowSeverity: true,
        mediumSeverity: true,
        criticalSeverity: true,
        commentTickets: true,
      },
    },
    [SubscriptionModule.CLOUD_SECURITY]: {
      accessMode: null,
      editAccessModePermission: {
        lowSeverity: true,
        mediumSeverity: true,
        criticalSeverity: true,
        commentTickets: true,
      },
    },
    [SubscriptionModule.USER_DATA_GOVERNANCE]: {
      accessMode: null,
      editAccessModePermission: {
        lowSeverity: true,
        mediumSeverity: true,
        criticalSeverity: true,
        commentTickets: true,
      },
    },
  },
};

export const emptyMspPermissions = {
  mspPortalScope: {
    mspPortalAccess: {
      accessMode: null,
      editAccessModePermission: {
        editWorkspace: true,
        startSubscription: true,
        stopSubscription: true,
        editSubscription: true,
        createChildWorkspace: true,
        createChannelWorkspace: true,
        archiveWorkspace: true,
        generateMspExport: true,
        generateMspSummaryReport: true,
        exportMspNotifications: true,
      },
    },
    channelAdminsAccess: {
      accessMode: null,
      editAccessModePermission: {
        add: true,
        remove: true,
        assignRoles: true,
      },
    },
    channelRolesAccess: {
      accessMode: null,
      editAccessModePermission: {
        add: true,
        edit: true,
        remove: true,
      },
    },
  },
  ...emptyPermissions,
};

export const emptyGlobalPermissions = {
  globalScope: {
    globalAdminsAccess: {
      accessMode: null,
      editAccessModePermission: {
        add: true,
        remove: true,
        assignRoles: true,
      },
    },
    globalRolesAccess: {
      accessMode: null,
      editAccessModePermission: {
        add: true,
        remove: true,
        edit: true,
      },
    },
    socPortalAccess: {
      accessMode: null,
      editAccessModePermission: {
        lowSeverity: true,
        mediumSeverity: true,
        criticalSeverity: true,
        commentTickets: true,
        tier1Statuses: true,
        tier2Statuses: true,
      },
    },
    workspaceSubscriptionTypeAccess: {
      prospects: true,
      customers: true,
    },
    workspaceTypeAccess: {
      regular: true,
      channel: true,
      child: true,
    },
    specialPermissions: {
      collectLogs: true,
      collectQuarantineData: true,
      showAdvancedDeviceInformation: true,
      backOffice: true,
      manageEndpointAutoUpdateGroups: true,
      // TODO: uncomment in future when needed again
      // manageWorkspaceCodes: true,
      demoMode: true,
    },
  },
  mspPortalScope: {
    mspPortalAccess: {
      accessMode: null,
      editAccessModePermission: {
        editWorkspace: true,
        startSubscription: true,
        stopSubscription: true,
        editSubscription: true,
        createChildWorkspace: true,
        createChannelWorkspace: true,
        createRegularWorkspace: true,
        archiveWorkspace: true,
        extendTrial: true,
        convertWorkspace: true,
        restoreWorkspace: true,
        generateMspExport: true,
        generateMspSummaryReport: true,
        exportMspNotifications: true,
      },
    },
    channelAdminsAccess: {
      accessMode: null,
      editAccessModePermission: {
        add: true,
        remove: true,
        assignRoles: true,
      },
    },
    channelRolesAccess: {
      accessMode: null,
      editAccessModePermission: {
        add: true,
        edit: true,
        remove: true,
      },
    },
  },
  ...emptyPermissions,
};
