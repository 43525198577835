<template>
  <div
    v-if="localValue?.wizardParameters.isDefault"
    class="mt-2 d-flex flex-column align-center pa-16"
  >
    <v-icon class="mb-3" size="80" icon="$endpointDataGovernance" />
    <span
      class="headline6 mb-3"
      v-text="$t(`endpointDataGovernance.privacySensitiveDataTab.emptyState.title`)"
    />
    <span
      class="text-center body1 mb-3"
      v-html="$t(`endpointDataGovernance.privacySensitiveDataTab.emptyState.subtitle`)"
    />
    <v-btn
      class="mt-4"
      color="primary"
      rounded
      @click="onSetParametersClick"
      :text="$t(`endpointDataGovernance.privacySensitiveDataTab.emptyState.cta`)"
    />
  </div>
  <v-skeleton-loader
    v-else
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS"
  >
    <div class="d-flex justify-space-between mb-4">
      <v-card class="sensitive-data-wrapper mr-4 pa-2 pt-3" v-if="localValue">
        <v-card-text>
          <div class="subtitle1">
            {{ $t("endpointDataGovernance.privacySensitiveDataTab.title") }}
          </div>
          <div
            class="mb-4"
            v-html="$t('endpointDataGovernance.privacySensitiveDataTab.sensitiveDataSubtitle')"
          ></div>
          <div class="subtitle1">
            {{ $t("endpointDataGovernance.privacySensitiveDataTab.recommendedDataTypes") }}
          </div>
          <div>
            <v-checkbox
              v-for="parameter in privacyParameters.recommended"
              :key="parameter"
              hide-details
              :disabled="actionNotAllowed"
              v-model="
                (localValue as PrivacySensitiveDataSettings)[
                  parameter as keyof PrivacySensitiveDataSettings
                ]
              "
              :label="$t(`endpointDataGovernance.privacySensitiveDataTab.${parameter}`)"
            >
            </v-checkbox>
          </div>
          <div class="subtitle1 mt-4">
            {{ $t("endpointDataGovernance.privacySensitiveDataTab.otherDataTypes") }}
          </div>
          <span v-if="privacyParameters.others.length === 0">
            {{ $t("endpointDataGovernance.privacySensitiveDataTab.noOtherDataTypes") }}
          </span>
          <v-checkbox
            v-for="parameter in privacyParameters.others"
            :key="parameter"
            hide-details
            :disabled="actionNotAllowed"
            v-model="
              (localValue as PrivacySensitiveDataSettings)[
                parameter as keyof PrivacySensitiveDataSettings
              ]
            "
            :label="$t(`endpointDataGovernance.privacySensitiveDataTab.${parameter}`)"
          >
          </v-checkbox>
        </v-card-text>
      </v-card>

      <v-card class="w-25">
        <v-card-text>
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="subtitle1">
              {{ $t("endpointDataGovernance.privacySensitiveDataTab.selectedParameters.title") }}
            </div>
            <span class="coro-link" @click="onSetParametersClick">
              {{ $t("general.edit") }}
            </span>
          </div>
          <div class="mb-6">
            <div class="subtitle1 mb-2">
              {{
                $t("endpointDataGovernance.privacySensitiveDataTab.selectedParameters.industries")
              }}
            </div>
            <div v-for="industry in localValue?.wizardParameters.industries" :key="industry">
              <span>{{
                $t(`modals.setUserDataGovernanceParameters.steps.industry.industries.${industry}`)
              }}</span>
            </div>
          </div>
          <div>
            <div class="subtitle1 mb-2">
              {{
                $t("endpointDataGovernance.privacySensitiveDataTab.selectedParameters.locations")
              }}
            </div>
            <div v-for="location in localValue?.wizardParameters.locations" :key="location">
              <span>{{
                $t(`modals.setUserDataGovernanceParameters.steps.location.locations.${location}`)
              }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type Ref, ref, watch } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import {
  defaultPrivacySensitiveDataState,
  type PrivacySensitiveDataSettings,
  usePrivacySensitiveDataStore,
} from "@/_store/endpoint-data-governance/privacy-sensitive-data.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { Locations } from "@/constants/data-governance.ts";
import { EndpointDataGovernanceAction } from "@/constants/endpoint-data-governance";
import { componentDialogsConfigConstructor } from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import PrivacyParameters from "@/components/modals/data-governance/PrivacyParameters.vue";

export default defineComponent({
  setup() {
    const privacySensitiveDataStore = usePrivacySensitiveDataStore();
    const dialogsStore = useDialogsStore();
    const { getSettings, updateSettings } = privacySensitiveDataStore;
    const { openDialog } = dialogsStore;
    const privacyParameters: Ref<{ recommended: string[]; others: string[] }> = ref({
      recommended: [],
      others: [],
    });

    const localValue: Ref<PrivacySensitiveDataSettings> = ref(
      defaultPrivacySensitiveDataState.settings
    );

    const settings = computed(() => privacySensitiveDataStore.settings);
    const showSkeletonLoader = computed(() => privacySensitiveDataStore.showSkeletonLoader);
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
      );
    });

    const getInitialParameters = (): (keyof PrivacySensitiveDataSettings)[] => {
      const parameters: Record<string, (keyof PrivacySensitiveDataSettings)[]> = {
        [Locations.USA]: ["monitorPII", "monitorPCI", "monitorPHI", "monitorNPI"],
        [Locations.ITALY]: [
          "monitorNonPublicData",
          "monitorPersonalData",
          "monitorHealthData",
          "monitorCreditCardData",
        ],
      };

      const locations = localValue.value?.wizardParameters.locations || [];
      const hasNorthAmerica =
        locations.includes(Locations.USA) || locations.includes(Locations.CANADA);
      const hasEurope = locations.includes(Locations.ITALY) || locations.includes(Locations.SPAIN);

      if (hasNorthAmerica && hasEurope) {
        return [...parameters[Locations.USA], ...parameters[Locations.ITALY]];
      }

      return hasNorthAmerica ? parameters[Locations.USA] : parameters[Locations.ITALY];
    };

    const setPrivacyParameters = () => {
      privacyParameters.value = getInitialParameters().reduce(
        (
          acc: { recommended: string[]; others: string[] },
          parameter: keyof PrivacySensitiveDataSettings
        ) => {
          if ((localValue.value as PrivacySensitiveDataSettings)[parameter]) {
            acc.recommended.push(parameter);
          } else {
            acc.others.push(parameter);
          }
          return acc;
        },
        {
          recommended: [],
          others: [],
        }
      );
    };

    const onSetParametersClick = () => {
      const dialogConfig = {
        ...componentDialogsConfigConstructor({
          action: EndpointDataGovernanceAction.SET_ENDPOINT_DATA_GOVERNANCE_PARAMETERS,
          component: PrivacyParameters,
          width: ModalWidth.MEDIUM,
          hideFooter: true,
          item: localValue.value?.wizardParameters.isDefault
            ? { selectedIndustries: [], selectedLocations: [] }
            : localValue.value?.wizardParameters,
          callback: () => [],
        }),
      };

      openDialog(dialogConfig);
    };

    onMounted(async () => {
      await getSettings();
      setPrivacyParameters();
    });
    watch(
      () => settings.value?.wizardParameters,
      (newVal) => {
        const shouldUpdatePrivacySettings = !isEqual(localValue.value?.wizardParameters, newVal);
        localValue.value = cloneDeep(settings.value);
        if (shouldUpdatePrivacySettings) {
          setPrivacyParameters();
        }
      },
      { deep: true }
    );

    watch(
      () => localValue.value,
      debounce(async (newVal: PrivacySensitiveDataSettings | null) => {
        if (!isEqual(newVal, settings.value)) {
          await updateSettings(newVal!);
        }
      }, 500),
      { immediate: false, deep: true }
    );

    return {
      localValue,
      showSkeletonLoader,
      actionNotAllowed,
      coronetSkeletonLoaderTypes,
      privacyParameters,
      onSetParametersClick,
    };
  },
});
</script>

<style scoped>
.sensitive-data-wrapper {
  flex-grow: 1;
}
</style>
