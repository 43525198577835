<template>
  <div class="content-md margin-auto mt-4">
    <div class="d-flex align-center justify-space-between mb-1">
      <div class="d-flex align-center">
        <span class="headline5" data-testid="ticket-log-page-title">
          {{ $t("mspTicketsPage.title") }}
        </span>
      </div>
      <div
        @click="exportCsv"
        class="export-btn body2 coro-link item-clickable d-flex align-center mr-4"
      >
        <v-icon icon="$export" color="link" size="16" />
        <span>{{ $t("general.exportCsv") }}</span>
      </div>
    </div>
    <div class="d-flex justify-space-between align-center mb-6">
      <v-tabs
        v-model="localFilters.status"
        align-with-title
        class="overflow-visible flex-b"
        color="orange-base"
        background-color="white"
        slider-color="orange-base"
        :height="48"
        :show-arrows="false"
      >
        <v-tab
          v-for="item in tabs"
          :key="item.id"
          class="mr-8 opacity-100"
          :height="42"
          :value="item.status"
          variant="plain"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </div>
    <filter-wrapper
      class="flex-grow-1 mb-4"
      :show-clear-button="showClearFiltersButton"
      @clear-filters-clicked="clearFilters()"
    >
      <div class="grid-container">
        <msp-workspace-descentant-select
          class="mr-2 customer-filter"
          :value="localFilters.childWorkspaceIds as string[]"
          @value-updated="localFilters.childWorkspaceIds = $event"
        />
        <v-select
          v-model="localFilters.widget"
          :items="widgets"
          :menu-props="{ maxHeight: '300' }"
          class="widget-filter filter-menu"
          :class="{ 'filter-active': localFilters.widget }"
          :placeholder="$t('ticketDetails.filters.widgets.placeholder')"
          data-testid="tickets-page-widget-type-filter"
          density="compact"
          variant="outlined"
          rounded
          hide-details
          background-color="white"
          @update:model-value="localFilters.eventTriggers = []"
        >
          <template #selection="{ item }">
            <span class="body2">
              {{ $t(`ticketDetails.filters.widgets.${item.title}`) }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-item
              v-bind="props"
              :title="$t(`ticketDetails.filters.widgets.${item.title}`)"
              :data-testid="`tickets-page-widget-type-filter-${item}-item`"
            >
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="localFilters.eventTriggers"
          :items="triggers"
          :menu-props="{ maxHeight: '300' }"
          class="filter-menu"
          :class="{ 'filter-active': !!localFilters.eventTriggers?.length }"
          :placeholder="$t('ticketDetails.filters.type')"
          data-testid="tickets-page-trigger-filter"
          density="compact"
          variant="outlined"
          multiple
          rounded
          hide-details
          background-color="white"
          item-value="value"
          item-title="value"
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">
              {{ $t("manageWorkspaces.filters.type") }}
            </span>
          </template>
          <template #label>
            <span v-if="localFilters.eventTriggers?.length" class="filter-label">
              {{ localFilters.eventTriggers.length }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="item.raw.type === 'subheader'" :title="item.raw.value" />
            <v-list-item v-else v-bind="props">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>

              <template v-slot:title>
                {{ $t(`tickets.eventTriggers.${item.title}`) }}
              </template>
            </v-list-item>
          </template>
        </v-select>
        <date-range-picker
          v-model="localFilters.eventTimeRange"
          class="daterange-picker"
          :class="{
            'filter-active': localFilters.eventTimeRange.start && localFilters.eventTimeRange.end,
          }"
          :presets="presets"
          :placeholder="$t('ticketDetails.filters.during')"
          :input-props="{
            'append-icon': 'icon-triangle',
            'hide-details': true,
          }"
          next-icon="icon-expand"
          data-testid="tickets-page-time-range-filter"
          prev-icon="icon-minimize"
          density="compact"
          variant="outlined"
        />
        <v-combobox
          v-model="localFilters.search"
          density="compact"
          variant="outlined"
          filled
          rounded
          clearable
          clear-icon="icon-x"
          background-color="white"
          :items="suggestions"
          :placeholder="$t('general.search')"
          prepend-inner-icon="icon-search"
          append-icon=""
          hide-details
          data-testid="tickets-page-search-field"
          @keyup.enter.prevent
          @keydown.enter.prevent
        >
        </v-combobox>
      </div>
    </filter-wrapper>
    <list-with-preview :show-skeleton-loader="showLoader">
      <template #list>
        <list-table
          :items="tickets"
          item-key="eventId"
          :pagination="pagination"
          :loading="loading"
          :selected-items="selectedItems"
          :is-indeterminate="isIndeterminate"
          :all-items-selected="allItemsSelected"
          :selected-items-quantity="selectedItemsQuantity"
          :total-items="allTickets"
          :available-bulk-actions="[]"
          :actions-loading="false"
          @page-changed="onPageChanged"
          @update-preview="ticketPreview = $event"
          @handle-one-row-selection="handleOneRowSelection($event.selected, $event.item)"
          @handle-all-rows-selection="handleAllRowsSelection"
        >
          <template #list-item="{ item, index }">
            <div class="list-item-grid py-1">
              <div class="list-item-grid__icon-block">
                <v-icon class="tickets-table--list__icon" :icon="`$${getIconName(item)}`"></v-icon>
              </div>

              <div class="list-item-grid__details-block d-flex flex-column justify-center px-2">
                <div
                  :data-testid="`tickets-table-type-col-row-${index}`"
                  class="tickets-table--list__name subtitle2"
                >
                  {{ $t(`tickets.eventTriggers.${camelCase(item.eventTrigger)}`) }}
                </div>
                <div class="body2" :data-testid="`tickets-table-users-row-${index}`">
                  <span v-if="shouldShowUsersNumber(item.eventType)">
                    {{ $t("general.nUsers", { n: item.usersAffected }) }}
                  </span>
                  <span v-else>
                    {{ getDisplayedUsers(item)[0] }}
                    <span v-if="getDisplayedUsers(item).length > 1">
                      {{ $t("eventsPage.more", { n: getDisplayedUsers(item).length - 1 }) }}
                    </span>
                  </span>
                </div>
                <div v-if="item.workspaceName" class="body2">
                  {{ item.workspaceName }}
                </div>
                <div v-if="item.processName" class="body2">
                  {{ item.processName }}
                </div>
                <div
                  class="caption text-indigo-medium"
                  :data-testid="`tickets-table-date-row-${index}`"
                >
                  {{ getFormattedDateTime(item.eventTime) }}
                </div>
              </div>

              <div class="list-item-grid__status-block">
                <ticket-list-status-icons
                  class="mt-2 justify-end"
                  :index="index"
                  :potentially-protectable-users="getPotentiallyProtectableUsers(item)"
                  :processed="item.processed"
                >
                  <template v-if="item.workspaceId && item.hasUnreadComment" #soc-comments-label>
                    <v-icon size="25" icon="$comments"></v-icon>
                  </template>
                </ticket-list-status-icons>
              </div>

              <div class="list-item-grid__bottom-block">
                <div class="d-flex align-center mb-4">
                  <users-say
                    :suspicious-vote-count="item.suspiciousVoteCount"
                    :safe-vote-count="item.safeVoteCount"
                  >
                    <template #soc-status-label>
                      <div
                        v-if="showProcessStatus(item)"
                        class="caption mt-1"
                        :class="{
                          'text-green-dark': item.processStatus === EdrProcessStatus.ALLOWED,
                          'text-red-dark': item.processStatus === EdrProcessStatus.BLOCKED,
                        }"
                      >
                        {{ $t(`general.${item.processStatus}`) }}
                      </div>
                    </template>
                  </users-say>
                </div>
              </div>
            </div>
          </template>
        </list-table>
      </template>
      <template #preview>
        <msp-ticket-preview :ticket="ticketPreview"></msp-ticket-preview>
      </template>
    </list-with-preview>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { defaultFiltersState, FilterContext } from "@/_store/filters.module";
import ListWithPreview from "@/components/ListWithPreview.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import {
  eventTriggerFilterMap,
  TicketsModuleFilter,
  TicketStatus,
  TicketTrigger,
  ticketTriggerIcons,
  TicketType,
  ticketTypeIcons,
  ViolationDirection,
} from "@/constants/tickets";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { getDateRangePresets } from "@/constants/date-range-picker.ts";
import { useI18n } from "vue-i18n";
import { useFilters } from "@/composables/useFilters.ts";
import { useSelectorStore } from "@/_store/selector.module.ts";
import { type RouteLocationNormalizedLoadedGeneric, useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { debounce, union } from "lodash";
import type { Pagination } from "@/types.ts";
import { type MspTicket, useMspTicketsStore } from "@/_store/msp/tickets/msp-tickets.module.ts";
import ListTable from "@/components/tables/ListTable.vue";
import { useSimpleSelectableTable } from "@/composables/useSimpleSelectableTable.ts";
import { EdrProcessStatus } from "@/constants/edr.ts";
import intersection from "lodash/intersection";
import {
  arrayToLowerCase,
  componentDialogsConfigConstructor,
  getFormattedDateTime,
} from "@/_helpers/utils.ts";
import UsersSay from "@/components/tickets/UsersSay.vue";
import camelCase from "lodash/camelCase";
import MspTicketPreview from "@/components/msp/tickets/MspTicketPreview.vue";
import TicketListStatusIcons from "@/components/TicketListStatusIcons.vue";
import MspWorkspaceDescentantSelect from "@/components/msp/MspWorkspaceDescentantSelect.vue";
import { useWorkspacesStore } from "@/_store/workspaces.module.ts";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import { ModalWidth } from "@/constants/modals.ts";
import MspExportTicketsModal from "@/components/modals/msp/MspExportTicketsModal.vue";
import { MspExportAction } from "@/constants/msp-exports.ts";

const filterContext = FilterContext.MSP_TICKETS;

export default defineComponent({
  components: {
    MspWorkspaceDescentantSelect,
    UsersSay,
    TicketListStatusIcons,
    ListTable,
    FilterWrapper,
    DateRangePicker,
    ListWithPreview,
    MspTicketPreview,
  },
  setup() {
    const i18n = useI18n();
    const {
      localFilters,
      filtersUpdating,
      clearFilters: clearFiltersFromComposable,
    } = useFilters(filterContext);
    const ticketsStore = useMspTicketsStore();
    const selectorStore = useSelectorStore();
    const dialogsStore = useDialogsStore();

    const router = useRouter();
    const ticketPreview = ref<MspTicket | null>(null);
    const { workspaceAndPermissionsUpdatedTimestamp } = storeToRefs(useWorkspacesStore());
    const { selection } = storeToRefs(selectorStore);
    const { deselectAllPages } = selectorStore;

    const { tickets, pagination, allTickets, loading, showSkeletonLoader, ticketDetailsLoading } =
      storeToRefs(ticketsStore);

    const { getMspTickets, resetPagination } = ticketsStore;

    const showClearFiltersButton = computed(() => {
      return !isEqual(
        { ...localFilters.value, status: null },
        { ...defaultFiltersState[filterContext], status: null }
      );
    });

    const {
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      clearSelection,
    } = useSimpleSelectableTable(tickets);

    const clearFilters = () => {
      localFilters.value = cloneDeep({
        ...defaultFiltersState[filterContext],
        status: localFilters.value.status,
      });
    };

    const presets = computed(() => getDateRangePresets());

    const tabs = computed(() => [
      { id: 0, name: i18n.t("general.open"), status: TicketStatus.OPEN },
      { id: 1, name: i18n.t("general.processed"), status: TicketStatus.CLOSED },
      { id: 2, name: i18n.t("general.all"), status: TicketStatus.ALL },
    ]);

    const currentTab = computed(() => {
      return tabs.value.find((tab) => tab.status === localFilters.value.status)!;
    });

    const triggers = computed(() => {
      return localFilters.value.widget
        ? [
            {
              type: "subheader",
              value: i18n.t(`ticketDetails.filters.widgets.${localFilters.value.widget}`),
            },
            ...sortTriggers(eventTriggerFilterMap[localFilters.value.widget]).map((v) => ({
              type: v,
              value: v,
            })),
          ]
        : Object.entries(eventTriggerFilterMap).flatMap(([widget, triggers]) => [
            {
              type: "subheader",
              value: i18n.t(`ticketDetails.filters.widgets.${widget}`),
            },
            ...sortTriggers(triggers).map((v) => ({ type: v, value: v })),
          ]);
    });

    const showLoader = computed(() => {
      return showSkeletonLoader.value && ticketDetailsLoading.value;
    });

    watch(
      localFilters,
      debounce(() => {
        deselectAllPages();
      }, 300),
      { deep: true }
    );

    const sortTriggers = (triggers: TicketTrigger[]) => {
      const sortedTriggers: TicketTrigger[] = triggers
        .filter((e) => e !== TicketTrigger.EMAIL_PHISHING)
        .sort((a, b) =>
          i18n.t(`tickets.eventTriggers.${a}`).localeCompare(i18n.t(`tickets.eventTriggers.${b}`))
        );

      if (triggers.includes(TicketTrigger.EMAIL_PHISHING)) {
        sortedTriggers.push(TicketTrigger.EMAIL_PHISHING);
      }

      return sortedTriggers;
    };

    watch(
      filtersUpdating,
      async (val) => {
        if (val) {
          resetPagination();
          await getMspTickets(false);
        }
      },
      { deep: true, immediate: true }
    );

    const onPageChanged = async (payload: Pagination) => {
      if (!isEqual(payload, pagination)) {
        ticketsStore.$patch({ pagination: payload });
        await getMspTickets(false);
      }
    };

    const applyQueryParams = (route: RouteLocationNormalizedLoadedGeneric) => {
      const result = cloneDeep(localFilters.value);

      Object.keys(localFilters.value).forEach((k) => {
        const param = route.query[k] as string;
        if (param) {
          const key = k as keyof typeof localFilters.value;
          if (Array.isArray(localFilters.value[key])) {
            Object.assign(result, { [key]: param.split(",") });
          } else {
            Object.assign(result, { [key]: param });
          }
        }
      });

      const startTime = route.query.startTime as string;
      const endTime = route.query.endTime as string;

      if (startTime && endTime) {
        result.eventTimeRange = {
          start: startTime,
          end: endTime,
        };
      }

      const shouldMakeRequest = isEqual(localFilters.value, result);
      localFilters.value = cloneDeep(result);

      return shouldMakeRequest;
    };

    onMounted(async () => {
      const route = useRoute();

      const shouldMakeRequest = applyQueryParams(route);
      if (shouldMakeRequest) {
        await getMspTickets();
      }
      await nextTick();
      await Promise.resolve(); // This ensures all previous awaits are fully resolved
      router.replace({ query: {} }).catch(() => {});
    });

    const shouldShowUsersNumber = (ticketType: TicketType) => {
      return [TicketType.SIMULATED_EMAIL_PHISHING, TicketType.SECURITY_TRAINING_FAILURE].includes(
        ticketType
      );
    };

    const getDisplayedUsers = (ticket: MspTicket) => {
      if (
        [TicketType.EMAIL_PHISHING, TicketType.MALWARE_IN_EMAIL_ATTACHMENTS].includes(
          ticket.eventType
        )
      ) {
        return ticket.to ? ticket.to : [];
      }
      if (ticket.lastLoggedInUsers?.length) {
        return ticket.lastLoggedInUsers ?? [];
      }
      const violationDirection = ticket.userDataAccessViolation?.violationDirection;
      if (violationDirection === ViolationDirection.ACCESS) {
        return union(ticket.userDataAccessViolation.users, ticket.potentiallyProtectableUsers);
      } else if (violationDirection === ViolationDirection.SHARE) {
        return ticket.userDataAccessViolation.users;
      }
      return ticket.from ? [ticket.from] : [];
    };

    const getIconName = ({ eventType, eventTrigger }: MspTicket) => {
      return ticketTypeIcons[eventType] || ticketTriggerIcons[eventTrigger];
    };

    const showProcessStatus = (item: MspTicket) => {
      return (
        item.processStatus === EdrProcessStatus.ALLOWED ||
        item.processStatus === EdrProcessStatus.BLOCKED
      );
    };

    const getPotentiallyProtectableUsers = (ticket: MspTicket) => {
      if (
        ticket.eventType === TicketType.EMAIL_PHISHING &&
        ticket.potentiallyProtectableUsers?.length
      ) {
        return ticket.potentiallyProtectableUsers;
      }

      return intersection(
        arrayToLowerCase(ticket.potentiallyProtectableUsers ?? []),
        arrayToLowerCase(getDisplayedUsers(ticket))
      );
    };

    const exportCsv = () => {
      dialogsStore.openDialog({
        ...componentDialogsConfigConstructor({
          action: MspExportAction.EXPORT_TICKETS,
          callback: async (data) => {
            await ticketsStore.exportTicketsToCsv(data);
          },
          component: MspExportTicketsModal,
          width: ModalWidth.LARGE,
        }),
        disablePersistentAnimation: true,
      });
    };

    onBeforeUnmount(() => {
      ticketsStore.$reset();
      clearFiltersFromComposable();
    });

    watch(workspaceAndPermissionsUpdatedTimestamp, async (newVal) => {
      if (newVal) {
        ticketsStore.$reset();
        await clearFiltersFromComposable();
        await getMspTickets();
      }
    });

    return {
      exportCsv,
      EdrProcessStatus,
      camelCase,
      localFilters,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      triggers,
      showLoader,
      tickets,
      allTickets,
      selection,
      pagination,
      loading,
      onPageChanged,
      tabs,
      currentTab,
      ticketPreview,
      suggestions: [
        "violator:",
        "domain:",
        "affected-user:",
        "process-name:",
        "process-hash:",
        "path:",
        "command-line:",
      ],
      coronetSkeletonLoaderTypes,
      widgets: Object.values(TicketsModuleFilter),
      presets,
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      clearSelection,
      shouldShowUsersNumber,
      getDisplayedUsers,
      getIconName,
      showProcessStatus,
      getPotentiallyProtectableUsers,
      getFormattedDateTime,
    };
  },
});
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns:
    minmax(150px, 1fr) minmax(240px, 1fr) minmax(200px, 1fr) minmax(240px, 1fr)
    minmax(240px, 1fr);
  align-items: center;
  gap: 8px;
  width: 100%;
}

.export-btn {
  text-decoration: none !important;
  .icon {
    text-decoration: none;
  }

  &:hover span {
    text-decoration: underline; /* Underline only the text */
  }
}

:deep(*) {
  .icon-export:before {
    color: rgb(var(--v-theme-anchor-base)) !important;
  }

  .nav-tabs {
    max-width: 300px;
  }

  .v-chip-group .v-chip {
    min-width: 32px;
  }

  .v-list-item__title {
    font-size: 16px !important;
    color: rgb(var(--v-theme-primary)) !important;
  }

  .clear-btn {
    z-index: 0 !important;
  }

  .search-field {
    .v-text-field__slot {
      z-index: 10 !important;
    }
  }

  .v-date-range__input-field .v-input__slot {
    padding: 0 8px 0 16px !important;
  }
}
</style>
