import { defineStore } from "pinia";

type SessionState = {
  deviceId: string;
};

export const useCurrentSessionStore = defineStore("session", {
  state: (): SessionState => ({ deviceId: "" }),
  persist: true,
  actions: {
    setDeviceId(payload: string) {
      this.deviceId = payload;
    },
  },
});
