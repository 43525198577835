<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="settings-header-with-btn">
      <div class="headline5">
        {{ $t("mspAccessControlPage.title") }}
      </div>
      <msp-create-role-button v-if="showCreateButton('msp-roles-tab')" />
      <msp-invite-admin-button v-else-if="showCreateButton('msp-admin-users-tab')" />
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-5" />
    <section class="content">
      <router-view></router-view>
    </section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useRoute } from "vue-router";
import MspCreateRoleButton from "@/views/msp/MspCreateRoleButton.vue";
import MspInviteAdminButton from "@/views/msp/MspInviteAdminButton.vue";
import { MspPortalScopeSection } from "@/_store/roles.module";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    MspInviteAdminButton,
    MspCreateRoleButton,
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const tabs = computed<CoroTab[]>(() => {
      return [
        {
          id: 1,
          name: i18n.t("mspAccessControlPage.tabs.mspAdminUsers"),
          route: { name: RouteName.MSP_ADMIN_USERS_TAB },
          hide: isMspAccessRestricted(MspPortalScopeSection.MSP_ADMIN_USERS),
        },
        {
          id: 2,
          name: i18n.t("mspAccessControlPage.tabs.mspRoles"),
          route: { name: RouteName.MSP_ROLES_TAB },
          hide: isMspAccessRestricted(MspPortalScopeSection.MSP_ROLES),
        },
      ]
        .filter((tab) => !tab.hide)
        .map(({ hide, ...tab }) => ({ ...tab }));
    });

    const showCreateButton = (selectedRoute: "msp-admin-users-tab" | "msp-roles-tab") => {
      return route.name === selectedRoute;
    };

    return {
      tabs,
      breadCrumbsItems: [
        {
          title: `‹ ${i18n.t("mspControlPanel.title")}`,
          disabled: false,
          to: { name: RouteName.MSP_CONTROL_PANEL_PAGE },
        },
      ],
      showCreateButton,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.nav-tabs) {
  .wrapper {
    z-index: 0 !important;
  }
}
</style>
