import { FilterContext, type MspTicketsFilters, useFiltersStore } from "@/_store/filters.module.ts";
import {
  TicketAction,
  TicketStatus,
  TicketTrigger,
  ViolationDirection,
} from "@/constants/tickets.ts";
import { convertTicketFiltersForBackend } from "@/_store/tickets/adapters.ts";
import type { DialogDataConfig } from "@/_store/dialogs.module.ts";
import type { Ticket, TicketDetails } from "@/_store/tickets/tickets.module.ts";
import { type SnackbarItemConfig, SnackbarTypes, useSnackbarStore } from "@/_store";
import { i18n } from "@/plugins/i18n.ts";
import { RouteName } from "@/constants/routes.ts";
import router from "@/_helpers/router.ts";

export const convertMspTicketFiltersForBackend = (filters: MspTicketsFilters) => {
  const childWorkspaceIds = filters.childWorkspaceIds ?? [];
  const { eventTriggers, fromTime, toTime, widget, search, protectedUsersOnly, processed } =
    convertTicketFiltersForBackend(filters);
  return {
    protectedUsersOnly,
    processed,
    eventTriggers,
    fromTime,
    toTime,
    widget,
    childWorkspaceIds: childWorkspaceIds.join(","),
    search,
    directDescendantsOnly: filters.directDescendantsOnly,
  };
};

export function handleTicketActionSnackbar(
  payload: DialogDataConfig<TicketDetails & { name?: string; closeTicket?: boolean }, TicketAction>
) {
  const createSnackbarMessage = (
    users: string[],
    violationDirection: ViolationDirection
  ): SnackbarItemConfig => {
    const getSnackbarTranslationKey = (payload: {
      action?: TicketAction;
      item?: Ticket | TicketDetails;
    }) => {
      const applySpecificApproveDescription = [
        TicketTrigger.MISSING_REQUIRED_AUTHENTICATION,
        TicketTrigger.CROWDBLOCKED_SENDER,
        TicketTrigger.BLOCKLISTED_SENDER,
      ].includes(payload.item!.eventTrigger);

      if (payload.action === TicketAction.GENERAL_APPROVE && applySpecificApproveDescription) {
        return `snackbar.messages.event.specificApprove.${payload.item?.eventTrigger}`;
      }

      return `snackbar.messages.event.${payload.action}`;
    };

    const snackbarItem = payload.item?.name;
    const dlpShortTriggerName = i18n.global.t(
      `ticketDetails.fullDetails.accessViolations.${payload.item.eventTrigger}`
    );

    const quantity =
      payload.action === TicketAction.ADD_TO_DATA_GOVERNANCE_PERMISSIONS ? users.length : 1;

    return {
      html: i18n.global.t(
        getSnackbarTranslationKey(payload),
        {
          item: snackbarItem,
          users: users.join(", "),
          violationDirection: i18n.global.t(`modals.${payload.action}.${violationDirection}`),
          trigger: dlpShortTriggerName,
        },
        quantity
      ),
      type: SnackbarTypes.SUCCESS,
    } as SnackbarItemConfig;
  };

  const createClosedTicketSnackbar = (): SnackbarItemConfig => {
    const item = payload.item?.name;

    return {
      html: i18n.global.t("snackbar.messages.event.markAsProcessed", { item }, 1),
      type: SnackbarTypes.SUCCESS,
    } as SnackbarItemConfig;
  };

  const snackbarStore = useSnackbarStore();
  const users = payload.item?.sections?.userDataAccessViolation?.users ?? [];
  const violationDirection =
    payload.item?.sections?.userDataAccessViolation?.violationDirection ?? ViolationDirection.SHARE;

  const snackbarMessage = createSnackbarMessage(users, violationDirection);
  const closedTicketSnackbar = createClosedTicketSnackbar();

  const ticketsWithButtonInSnackbar = [
    TicketAction.MARK_AS_PROCESSED,
    TicketAction.MARK_AS_UNPROCESSED,
  ];

  if (ticketsWithButtonInSnackbar.includes(payload.action!)) {
    snackbarStore.add(
      snackbarWithViewBtn(snackbarMessage, { action: payload.action!, item: payload.item })
    );
  } else if (payload.item.closeTicket) {
    snackbarStore.add(
      snackbarWithViewBtn(closedTicketSnackbar, { action: payload.action!, item: payload.item })
    );
  } else {
    snackbarStore.add(snackbarMessage);
  }
}

function snackbarWithViewBtn(
  snackbar: SnackbarItemConfig,
  payload: {
    action: TicketAction;
    item?: { eventId: string };
    closeTicket?: boolean; // Used in soc
  }
) {
  snackbar.callback = async () => {
    const routeName = RouteName.MSP_TICKETS_PAGE;
    const context = FilterContext.MSP_TICKETS;
    const status =
      payload.action === TicketAction.MARK_AS_UNPROCESSED ? TicketStatus.OPEN : TicketStatus.CLOSED;

    const filtersStore = useFiltersStore();

    filtersStore.setFilters(context, {
      ...filtersStore.filters[context],
      status,
      search: payload.item?.eventId,
    });
    await router.push({
      name: routeName,
      query: { status, search: payload.item?.eventId },
    });
  };
  snackbar.callbackName = i18n.global.t("general.view");
  return snackbar;
}
