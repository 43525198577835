<template>
  <div class="content-md margin-auto">
    <v-card v-if="localValue?.wizardParameters.isDefault" class="mb-4">
      <v-card-text class="d-flex flex-column align-center pa-16">
        <v-icon class="mb-3" size="80" icon="$endpointDataGovernance" />
        <span
          class="headline6 mb-3"
          v-text="$t(`userDataGovernanceSettings.monitoringTab.dataPrivacyTitle`)"
        ></span>
        <span
          class="text-center body1 mb-3"
          v-html="$t(`userDataGovernanceSettings.monitoringTab.dataPrivacySubtitle`)"
        ></span>
        <v-btn
          class="mt-4"
          color="primary"
          rounded
          @click="onSetParametersClick"
          :text="$t(`userDataGovernanceSettings.monitoringTab.dataPrivacyCta`)"
        />
      </v-card-text>
    </v-card>
    <v-skeleton-loader
      class="skeleton-loader--security-settings"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS_DENSE"
    >
      <div v-if="!localValue?.wizardParameters.isDefault" class="d-flex justify-space-between mb-4">
        <v-card class="sensitive-data-wrapper mr-4 pa-2 pt-3" v-if="localValue">
          <v-card-text>
            <div class="subtitle1">
              {{ $t("userDataGovernanceSettings.monitoringTab.privacySensitiveData") }}
            </div>
            <div
              class="mb-4"
              v-html="$t('userDataGovernanceSettings.monitoringTab.sensitiveDataSubtitle')"
            ></div>
            <div class="subtitle1">
              {{ $t("userDataGovernanceSettings.monitoringTab.recommendedDataTypes") }}
            </div>
            <div>
              <v-checkbox
                v-for="parameter in privacyParameters.recommended"
                :key="parameter"
                v-model="
                  (localValue as UserDataGovernanceSettings)[
                    parameter as keyof UserDataGovernanceSettings
                  ]
                "
                :label="$t(`userDataGovernanceSettings.monitoringTab.${parameter}`)"
              ></v-checkbox>
            </div>
            <div class="subtitle1">
              {{ $t("userDataGovernanceSettings.monitoringTab.otherDataTypes") }}
            </div>
            <span v-if="privacyParameters.others.length === 0">
              {{ $t("userDataGovernanceSettings.monitoringTab.noOtherDataTypes") }}
            </span>
            <v-checkbox
              v-for="parameter in privacyParameters.others"
              :key="parameter"
              v-model="
                (localValue as UserDataGovernanceSettings)[
                  parameter as keyof UserDataGovernanceSettings
                ]
              "
              :label="$t(`userDataGovernanceSettings.monitoringTab.${parameter}`)"
            ></v-checkbox>
          </v-card-text>
        </v-card>
        <v-card class="w-25">
          <v-card-text>
            <div class="d-flex justify-space-between align-center mb-4">
              <div class="subtitle1">
                {{ $t("userDataGovernanceSettings.monitoringTab.selectedParameters.title") }}
              </div>
              <span class="coro-link" @click="onSetParametersClick">
                {{ $t("general.edit") }}
              </span>
            </div>
            <div class="mb-6">
              <div class="subtitle1 mb-2">
                {{ $t("userDataGovernanceSettings.monitoringTab.selectedParameters.industries") }}
              </div>
              <div v-for="industry in localValue?.wizardParameters.industries" :key="industry">
                <span>{{
                  $t(`modals.setUserDataGovernanceParameters.steps.industry.industries.${industry}`)
                }}</span>
              </div>
            </div>
            <div>
              <div class="subtitle1 mb-2">
                {{ $t("userDataGovernanceSettings.monitoringTab.selectedParameters.locations") }}
              </div>
              <div v-for="location in localValue?.wizardParameters.locations" :key="location">
                <span>{{
                  $t(`modals.setUserDataGovernanceParameters.steps.location.locations.${location}`)
                }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <v-card v-if="localValue">
        <v-card-text>
          <div class="custom-data-wrapper">
            <div class="subtitle1 mb-4">
              {{ $t("userDataGovernanceSettings.monitoringTab.securityAndBusinessData") }}
            </div>
            <v-checkbox
              v-model="localValue.customData.monitorPasswords"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-passwords-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.passwords')"
              class="mt-3 mb-1"
            />
            <v-checkbox
              v-model="localValue.customData.monitorCertificates"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-certificates-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.certificates')"
              class="mt-3 mb-1"
            />
            <v-checkbox
              v-model="localValue.customData.monitorSourceCodes"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-source-codes-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.sourceCode')"
              class="mt-3 mb-1"
            />
            <v-checkbox
              v-model="localValue.customData.monitorSensitiveKeywords"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-sensitive-keywords-checkbox"
              :ripple="false"
              :label="
                $t('userDataGovernanceSettings.monitoringTab.dataObjectsWithSpecificKeywords')
              "
              class="mt-3 mb-1"
            />
            <v-combobox
              v-model="localValue.customData.sensitiveKeywords"
              :disabled="actionNotAllowed || !localValue!.customData.monitorSensitiveKeywords"
              data-testid="custom-data-monitor-keywords-combobox"
              variant="outlined"
              multiple
              item-color="red"
              class="ml-7"
              :rules="sensitiveKeywordsRule"
              :placeholder="
                $t('userDataGovernanceSettings.monitoringTab.specificKeywordsPlaceholder')
              "
              @update:search="onKeywordInput('sensitiveKeywords')"
            >
              <template v-slot:chip="{ item, props }">
                <v-chip
                  v-bind="props"
                  :disabled="actionNotAllowed"
                  :data-testid="`chip-${item}`"
                  close-icon="$closeCircle"
                  :closable="true"
                  variant="flat"
                  color="indigo-faint"
                >
                  <div class="d-flex align-center justify-space-between">
                    <span class="ml-1 mr-2 chip-content__text">{{ item.value }}</span>
                  </div>
                </v-chip>
              </template>
              <template #item="data">
                <v-list-item>
                  <span class="ml-1">{{ data.item }}</span>
                </v-list-item>
              </template>
            </v-combobox>
            <v-checkbox
              v-model="localValue.customData.monitorSensitiveFileTypes"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-sensitive-file-types-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.specificFileTypesTitle')"
              class="mt-3 mb-1"
            />
            <v-combobox
              v-model="localValue.customData.sensitiveFileTypes"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-file-types-combobox"
              variant="outlined"
              multiple
              class="ml-7"
              :placeholder="
                $t('userDataGovernanceSettings.monitoringTab.specificFileTypesPlaceholder')
              "
              @update:search="onKeywordInput('sensitiveFileTypes')"
            >
              <template v-slot:chip="{ item, props }">
                <v-chip
                  v-bind="props"
                  :disabled="actionNotAllowed"
                  :data-testid="`chip-${item}`"
                  close-icon="$closeCircle"
                  :closable="true"
                  variant="flat"
                  color="indigo-faint"
                >
                  <div class="d-flex align-center justify-space-between">
                    <span class="ml-1 mr-2 chip-content__text">{{ item.value }}</span>
                  </div>
                </v-chip>
              </template>
              <template #item="data">
                <v-list-item>
                  <span class="ml-1">{{ data.item }}</span>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
        </v-card-text>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { useUserDataGovernanceModule } from "@/_store/user-data-governance/user-data-governance.module";
import type { UserDataGovernanceSettings } from "@/_store/user-data-governance/user-data-governance.module";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import { SubscriptionModule } from "@/constants/workplaces";
import {
  componentDialogsConfigConstructor,
  hasSeparator,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useI18n } from "vue-i18n";
import { debounce, uniq } from "lodash";
import isEqual from "lodash/isEqual";
import { UserDataGovernanceAction } from "@/constants/user-data-governance";
import { ModalWidth } from "@/constants/modals";
import PrivacyParameters from "@/components/modals/data-governance/PrivacyParameters.vue";
import { Locations } from "@/constants/data-governance";
export default defineComponent({
  setup() {
    const userDataStore = useUserDataGovernanceModule();
    const { settings, showSkeletonLoader } = storeToRefs(userDataStore);
    const localValue: Ref<UserDataGovernanceSettings | null> = ref(null);
    const privacyParameters: Ref<{ recommended: string[]; others: string[] }> = ref({
      recommended: [],
      others: [],
    });
    const i18n = useI18n();

    const { openDialog } = useDialogsStore();

    onMounted(async () => {
      await userDataStore.getSettings();
      localValue.value = cloneDeep(settings.value);

      setPrivacyParameters();
    });

    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.USER_DATA_GOVERNANCE
    );

    const sensitiveKeywordsRule = [
      (keywords: string[]) => {
        if (keywords?.length > 200) {
          return i18n.t("validations.maximumAllowedKeywords");
        }
        return true;
      },
    ];

    const getInitialParameters = (): (keyof UserDataGovernanceSettings)[] => {
      const parameters: Record<string, (keyof UserDataGovernanceSettings)[]> = {
        [Locations.USA]: ["monitorPII", "monitorPCI", "monitorPHI", "monitorNPI"],
        [Locations.ITALY]: [
          "monitorNonPublicData",
          "monitorPersonalData",
          "monitorHealthData",
          "monitorCreditCardData",
        ],
      };

      const locations = localValue.value?.wizardParameters.locations || [];
      const hasNorthAmerica =
        locations.includes(Locations.USA) || locations.includes(Locations.CANADA);
      const hasEurope = locations.includes(Locations.ITALY) || locations.includes(Locations.SPAIN);

      if (hasNorthAmerica && hasEurope) {
        return [...parameters[Locations.USA], ...parameters[Locations.ITALY]];
      }

      return hasNorthAmerica ? parameters[Locations.USA] : parameters[Locations.ITALY];
    };

    const setPrivacyParameters = () => {
      privacyParameters.value = getInitialParameters().reduce(
        (
          acc: { recommended: string[]; others: string[] },
          parameter: keyof UserDataGovernanceSettings
        ) => {
          if ((localValue.value as UserDataGovernanceSettings)[parameter]) {
            acc.recommended.push(parameter);
          } else {
            acc.others.push(parameter);
          }
          return acc;
        },
        {
          recommended: [],
          others: [],
        }
      );
    };

    watch(
      () => settings.value?.wizardParameters,
      (newVal) => {
        const shouldUpdatePrivacySettings = !isEqual(localValue.value?.wizardParameters, newVal);
        localValue.value = cloneDeep(settings.value);
        if (shouldUpdatePrivacySettings) {
          setPrivacyParameters();
        }
      },
      { deep: true }
    );

    watch(
      () => localValue.value,
      debounce(async (newVal) => {
        if (!isEqual(newVal, settings.value)) {
          await userDataStore.updateSettings(newVal);
        }
      }, 500),
      { immediate: false, deep: true }
    );

    const onKeywordInput = (arrayKey: "sensitiveKeywords" | "sensitiveFileTypes") => {
      const previousValues = localValue.value!.customData[arrayKey];
      const lastKeyword = previousValues?.at(-1);
      const hasCommaSeparator = hasSeparator(lastKeyword!);

      if (hasCommaSeparator) {
        const split = lastKeyword!.split(",");

        localValue.value!.customData[arrayKey] = uniq(
          [...localValue.value!.customData[arrayKey].slice(0, -1), ...split].map((item) =>
            item.toLowerCase().trim()
          )
        );
      }
    };

    const onSetParametersClick = () => {
      const dialogConfig = {
        ...componentDialogsConfigConstructor({
          action: UserDataGovernanceAction.SET_USER_DATA_GOVERNANCE_PARAMETERS,
          component: PrivacyParameters,
          width: ModalWidth.MEDIUM,
          hideFooter: true,
          item: localValue.value?.wizardParameters.isDefault
            ? { selectedIndustries: [], selectedLocations: [] }
            : localValue.value?.wizardParameters,
          callback: () => [],
        }),
      };

      openDialog(dialogConfig);
    };

    return {
      privacyParameters,
      coronetSkeletonLoaderTypes,
      settings,
      showSkeletonLoader,
      localValue,
      actionNotAllowed,
      sensitiveKeywordsRule,
      onKeywordInput,
      onSetParametersClick,
      Locations,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-field__input {
    opacity: 1;
  }

  .v-field .v-chip {
    --v-chip-height: 32px;
  }
}
.sensitive-data-wrapper {
  flex-grow: 1;
}
.chip-item {
  height: 36px;
  border-radius: 18px;
}

.category-icon {
  font-size: 40px;
}

.chip-content__text {
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
