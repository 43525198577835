import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { StoreModule } from "@/constants/store.ts";
import type { CommentSettings, TicketCommentsState } from "@/_store/ticket-comments.module.ts";
import cloneDeep from "lodash/cloneDeep";

const defaultCommentsState = {
  comments: [],
  loading: false,
  totalComments: 0,
  pagination: {
    page: 0,
    pageSize: 5,
  },
};

export const useMspTicketCommentsStore = defineStore(StoreModule.MSP_TICKET_COMMENTS, {
  state: (): TicketCommentsState => cloneDeep({ ...defaultCommentsState }),
  actions: {
    async getComments({ eventId, workspaceId }: { eventId: string; workspaceId: string }) {
      this.loading = true;
      const request = {
        ...api.getMspTicketComments(eventId),
        params: {
          ...this.pagination,
          workspaceId,
        },
      };
      try {
        const comments = await axiosInstance.request(request);
        this.comments = comments.data.items;
        this.totalComments = comments.data.total;
      } catch (e) {
        console.error(e);
      }

      this.loading = false;
    },
    async getNextPage({ eventId, workspaceId }: { eventId: string; workspaceId: string }) {
      this.pagination.page = this.pagination.page + 1;
      this.loading = true;
      const request = {
        ...api.getMspTicketComments(eventId),
        params: {
          ...this.pagination,
          workspaceId,
        },
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.comments = [...this.comments, ...data.items];
        this.totalComments = data.total;
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async addComment(
      payload: CommentSettings & {
        eventId: string;
        workspaceId?: string;
      }
    ) {
      this.loading = true;
      const {
        message,
        includeTicketDetails,
        notifyAffectedUsers,
        notifyAllWorkspaceAdmins,
        notifyCustomRecipients,
        customRecipients,
        eventId,
        notifySocContacts,
        workspaceId,
        notifyCoroAdmins,
        confidential,
      } = payload;

      const request = {
        ...api.getMspTicketComments(eventId),
        method: "POST",
        data: {
          message,
          includeTicketDetails,
          notifyAffectedUsers,
          notifyAllWorkspaceAdmins,
          notifyCustomRecipients,
          customRecipients,
          notifySocContacts,
          notifyCoroAdmins,
          confidential,
        },
        params: {
          workspaceId,
        },
      };

      try {
        await axiosInstance.request(request);
        this.pagination.page = 0;
        await this.getComments({ eventId, workspaceId: workspaceId! });
      } catch (e) {
        console.error(e);
      }
    },
  },
});
