<template>
  <div>
    <v-stepper-vertical hide-actions v-model="currentStep">
      <template v-slot:default>
        <v-stepper-vertical-item
          :complete="localValue.selectedIndustries.length > 0 && currentStep !== 1"
          complete-icon="$check"
          value="1"
          :elevation="0"
        >
          <template #title>
            <div class="d-flex justify-space-between align-center">
              <div class="subtitle1">
                {{ $t("modals.setUserDataGovernanceParameters.steps.industry.title") }}
              </div>
              <span v-if="currentStep !== 1" class="selected-industries body1 ml-2 ellipsis">{{
                localValue.selectedIndustries.join(", ")
              }}</span>
            </div>
          </template>
          <div class="mb-4 body2">
            {{ $t("modals.setUserDataGovernanceParameters.steps.industry.description") }}
          </div>
          <v-text-field
            v-model="search"
            class="search-input mb-4"
            density="comfortable"
            rounded
            min-width="400"
            clear-icon="$x"
            :placeholder="$t('general.search')"
            prepend-inner-icon="$search"
            clearable
            variant="filled"
            bg-color="indigo-faint"
            :hide-details="true"
          />
          <div class="industry-wrapper d-flex flex-column overflow-y-auto border pa-4 rounded">
            <div v-if="filteredIndustries.length === 0 && !!search" class="no-results">
              <span class="body1">{{
                $t("modals.setUserDataGovernanceParameters.noDataAvailable")
              }}</span>
              <span class="body1 ml-2 coro-link" @click="search = ''">{{
                $t("modals.setUserDataGovernanceParameters.clearToReset")
              }}</span>
              <span class="body1 ml-2">{{
                $t("modals.setUserDataGovernanceParameters.differentSearch")
              }}</span>
            </div>
            <v-checkbox
              v-for="(item, index) in filteredIndustries"
              :key="index"
              :label="item"
              v-model="localValue.selectedIndustries"
              :value="item"
            />
          </div>

          <template #actions="{ next }">
            <v-btn
              class="mt-4"
              :disabled="localValue.selectedIndustries.length === 0"
              rounded
              color="primary"
              variant="elevated"
              @click="next"
              >{{ $t("general.next") }}</v-btn
            >
            <v-btn class="mt-4 ml-4" rounded variant="elevated" @click="$emit('dismissed')">{{
              $t("general.cancel")
            }}</v-btn>
          </template>
        </v-stepper-vertical-item>
        <v-stepper-vertical-item value="2" :elevation="0">
          <template #title>
            <div class="subtitle1">
              {{ $t("modals.setUserDataGovernanceParameters.steps.location.title") }}
            </div>
          </template>
          <!-- TODO: Uncomment this when legal approve text -->
          <!-- 
          <div class="mb-4 body2">
            {{ $t("modals.setUserDataGovernanceParameters.steps.location.description") }}
          </div> -->
          <div class="d-flex flex-column border pa-2">
            <v-checkbox
              v-for="(item, index) in getLocations"
              :key="index"
              :label="item"
              v-model="localValue.selectedLocations"
              :value="item"
            />
          </div>
        </v-stepper-vertical-item>
      </template>
    </v-stepper-vertical>
  </div>
  <div v-if="currentStep !== 1" class="footer d-flex justify-space-between">
    <v-btn @click="currentStep = 1"> {{ $t("general.back") }} </v-btn>
    <div class="d-flex">
      <v-btn class="mr-2" @click="$emit('dismissed')"> {{ $t("general.cancel") }} </v-btn>
      <v-btn
        :disabled="saveButtonDisabled"
        color="primary"
        rounded
        @click="onSave"
        :loading="isLoading"
      >
        {{ $t("general.save") }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { Industries, Locations } from "@/constants/data-governance";
import {
  type UserDataGovernanceSettings,
  useUserDataGovernanceModule,
  type PrivacyParametersConfig,
  type PrivacyParameters,
} from "@/_store/user-data-governance/user-data-governance.module";
import {
  type PrivacySensitiveDataSettings,
  usePrivacySensitiveDataStore,
} from "@/_store/endpoint-data-governance/privacy-sensitive-data.module";
import { EndpointDataGovernanceAction } from "@/constants/endpoint-data-governance";
import { useI18n } from "vue-i18n";
import { UserDataGovernanceAction } from "@/constants/user-data-governance.ts";

interface PrivacyParametersModalConfig {
  item?: {
    industries: string[];
    locations: string[];
  };
  action?: string;
}

interface LocalValue {
  selectedIndustries: string[];
  selectedLocations: string[];
}

export default defineComponent({
  emits: ["update:localValue", "change-footer", "hide-footer", "update:valid", "dismissed"],
  props: {
    config: {
      type: Object as PropType<PrivacyParametersModalConfig>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const userDataGovernanceModule = useUserDataGovernanceModule();
    const privacySensitiveDataStore = usePrivacySensitiveDataStore();
    const { settings: userDataGovernanceSettings } = storeToRefs(userDataGovernanceModule);

    const { updateSettings: updateUserDataGovernanceSettings, getPrivacyParametersConfig } =
      userDataGovernanceModule;
    const { updateSettings: updatePrivacySensitiveDataSettings } = privacySensitiveDataStore;
    const currentStep = ref(1);
    const search = ref("");
    const isLoading = ref(false);
    const localValue = ref<LocalValue>({
      selectedIndustries: [],
      selectedLocations: [],
    });

    onMounted(() => {
      localValue.value = {
        ...localValue.value,
        selectedIndustries: props.config?.item?.industries?.map(getTranslatedIndustry) || [],
        selectedLocations: props.config?.item?.locations?.map(getTranslatedLocation) || [],
      };
    });

    const saveButtonDisabled = computed(
      () =>
        localValue.value.selectedIndustries.length === 0 ||
        localValue.value.selectedLocations.length === 0
    );

    const onSave = async () => {
      try {
        isLoading.value = true;
        const { data: updatedParameters }: { data: PrivacyParametersConfig } =
          await getPrivacyParametersConfig({
            ...formattedData(),
          });

        switch (props.config?.action) {
          case EndpointDataGovernanceAction.SET_ENDPOINT_DATA_GOVERNANCE_PARAMETERS:
            await updatePrivacySensitiveDataSettings({
              ...updatedParameters,
            } as PrivacySensitiveDataSettings);
            break;
          case UserDataGovernanceAction.SET_USER_DATA_GOVERNANCE_PARAMETERS:
            const { customData } = userDataGovernanceSettings.value;
            await updateUserDataGovernanceSettings({
              ...updatedParameters,
              customData,
            } as UserDataGovernanceSettings);
            break;
        }
      } catch (error) {
        console.error(error);
      } finally {
        isLoading.value = false;
      }

      emit("dismissed");
    };

    const getTranslatedIndustry = (industry: string): string =>
      t(`modals.setUserDataGovernanceParameters.steps.industry.industries.${industry}`);

    const getTranslatedLocation = (location: string): string =>
      t(`modals.setUserDataGovernanceParameters.steps.location.locations.${location}`);

    const filteredIndustries = computed(() => {
      const industries = Object.values(Industries);

      if (!search.value) {
        return industries.map(getTranslatedIndustry);
      }

      const searchTerm = search.value.toLowerCase();
      return industries
        .filter((industry) => getTranslatedIndustry(industry).toLowerCase().includes(searchTerm))
        .map(getTranslatedIndustry);
    });

    const getLocations = computed(() =>
      Object.values(Locations).map((location) =>
        t(`modals.setUserDataGovernanceParameters.steps.location.locations.${location}`)
      )
    );

    const formattedData = () => {
      const industries = localValue.value.selectedIndustries.map((translatedIndustry: string) =>
        Object.values(Industries).find(
          (industry) => getTranslatedIndustry(industry) === translatedIndustry
        )
      );

      const locations = localValue.value?.selectedLocations.map((translatedLocation: string) =>
        Object.values(Locations).find(
          (location) => getTranslatedLocation(location) === translatedLocation
        )
      );
      return {
        industries,
        locations,
      } as PrivacyParameters;
    };

    return {
      saveButtonDisabled,
      onSave,
      isLoading,
      currentStep,
      search,
      localValue,
      filteredIndustries,
      getLocations,
    };
  },
});
</script>

<style scoped lang="scss">
.industry-wrapper {
  max-height: 320px;
}

.selected-industries {
  width: 350px;
}

:deep(*) {
  .v-stepper-vertical-item__avatar.v-avatar {
    background-color: rgb(var(--v-theme-indigo-pale));
    color: rgb(var(--v-theme-indigo-medium));
  }

  .v-expansion-panel-title--active .v-stepper-vertical-item__avatar.v-avatar {
    color: rgb(var(--v-theme-white));
    background-color: rgb(var(--v-theme-indigo-medium));
  }

  .v-expansion-panel--active:not(.v-stepper-vertical-item--error)
    .v-stepper-vertical-item__avatar.v-avatar {
    background-color: rgb(var(--v-theme-primary));
  }

  .v-stepper-vertical-item--error {
    .v-avatar {
      background: transparent !important;
    }
  }
}

:deep(.v-stepper-vertical-item--complete) {
  .v-stepper-vertical-item__avatar.v-avatar {
    background-color: rgb(var(--v-theme-green-base));
    .icon::before {
      color: white;
    }
  }
}

:deep(.search-input) {
  .v-field__outline {
    display: none;
  }
}

:deep(.v-expansion-panel-title) {
  padding: 0;
}

.v-stepper-vertical-item:not(:last-child):before {
  left: 11px;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0 0 16px 0;
}
</style>
